import {
  Anchor,
  Divider,
  Grid,
  Group,
  // List,
  MediaQuery,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Link } from 'react-router-dom';

import { BurgerMenu } from '@/components/BurgerMenu';
import { useGlobalStore } from '@/store/global';

import { ContactIcon } from './components/ContactIcon';

export const TopNav = () => {
  const theme = useMantineTheme();
  const { user } = useGlobalStore();
  const transition = {
    transition: 'filter 0.5s',
    '&:hover': {
      filter: 'invert(0.5)',
    },
  };
  return (
    <>
      <BurgerMenu />

      <MediaQuery
        smallerThan="md"
        styles={{
          paddingLeft: theme.spacing.lg,
          paddingRight: theme.spacing.lg,
        }}
      >
        <Grid
          gutter={0}
          px={100}
          style={{ height: 40 }}
          sx={(theme) => ({
            backgroundColor: theme.colors.gray[0],
          })}
          align="center"
        >
          <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
            <Grid.Col xs={12} md={6}>
              <Text weight="bold">Facilidade e satisfação em sua casa</Text>
            </Grid.Col>
          </MediaQuery>
          <Grid.Col xs={12} md={6}>
            <MediaQuery
              smallerThan="md"
              styles={{
                justifyContent: 'center',
                paddingLeft: theme.spacing.xl,
              }}
            >
              <Group position="right" align="center">
                <Group>
                  <ContactIcon
                    iconName="icon-phone"
                    text="968 675 057"
                    href="tel:+351 968 675 057"
                  />

                  <Divider orientation="vertical" color="dark" />

                  <ContactIcon
                    iconName="icon-mail"
                    text="geral@frutada5a.pt"
                    href="mailto:geral@frutada5a.pt"
                  />

                  <Divider orientation="vertical" color="dark" />

                  <Group spacing="xs">
                    <Anchor
                      sx={() => ({ ...transition })}
                      style={{ display: 'block' }}
                      color="dark"
                      size="xs"
                      href="https://www.fb.com/Frutada5a"
                    >
                      <i
                        className="icon-facebook"
                        style={{ fontSize: theme.fontSizes.md }}
                      />
                    </Anchor>
                    <Anchor
                      sx={() => ({ ...transition })}
                      style={{ display: 'block' }}
                      color="dark"
                      size="xs"
                      href="https://api.whatsapp.com/send?phone=351968675057"
                    >
                      <i
                        className="icon-whatsapp"
                        style={{ fontSize: theme.fontSizes.md }}
                      />
                    </Anchor>
                    <Anchor
                      sx={() => ({ ...transition })}
                      style={{ display: 'block' }}
                      color="dark"
                      size="xs"
                      href="https://www.instagram.com/frutada5a/"
                    >
                      <i
                        className="icon-instagram"
                        style={{ fontSize: theme.fontSizes.md }}
                      />
                    </Anchor>
                  </Group>
                  <Divider orientation="vertical" color="dark" />
                  <Anchor
                    component={Link}
                    sx={() => ({ ...transition })}
                    style={{ display: 'block' }}
                    color="dark"
                    size="xs"
                    to="/auth"
                  >
                    <i
                      className={user?.id ? 'icon-user' : 'icon-user-o'}
                      style={{ fontSize: theme.fontSizes.md }}
                    />
                  </Anchor>
                </Group>
              </Group>
            </MediaQuery>
          </Grid.Col>
        </Grid>
      </MediaQuery>
    </>
  );
};
