import {
  Center,
  Container,
  Group,
  Image,
  Loader,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import slugify from 'react-slugify';

import { ProductUI, useGetProductDetail } from '@/api/useGetProducts';
import { TopMenu } from '@/components/TopMenu';

import { AddProduct } from '../components/AddProduct';
import { ImageContainer } from '../components/ImageContainer';
import { PriceInfo } from '../components/PriceInfo';

export const Product = () => {
  const { slug } = useParams();

  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const { productDetail, categories, images, isLoading, attributes } =
    useGetProductDetail({
      productName: slugify(slug),
    });

  const {
    name,
    url,
    description,
    price,
    unit,
    weight,
    promotionValue,
    pricePerKg,
    promotionPrice,
    promotionPricePerKg,
    dateStart,
    dateEnd,
    minimum,
    productId,
  } = productDetail || ({} as ProductUI);

  if (isLoading)
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color="dark" />
      </Center>
    );
  return (
    <>
      <TopMenu />
      <Container size="xl" py="100px">
        <Stack>
          <Group spacing="xl" position="center" align={'start'} grow>
            <Stack
              spacing={0}
              style={{ flexGrow: 0, minWidth: breakpoint ? '220px' : '100%' }}
            >
              <ImageContainer
                url={url}
                name={name}
                promotionValue={promotionValue}
              />
            </Stack>
            <Stack
              spacing={'xs'}
              style={{ minWidth: breakpoint ? '220px' : '100%' }}
            >
              <Stack spacing={0}>
                <Title>{name}</Title>
                <Text size="sm" color="gray">
                  Categorias:
                  {categories?.map(({ name, categoryId }, index) => (
                    <Text component="span" key={categoryId || index}>
                      {name}
                      {index === categories.length - 1 ? '' : ', '}
                    </Text>
                  ))}
                </Text>
              </Stack>
              <PriceInfo
                unit={unit}
                dateEnd={dateEnd}
                dateStart={dateStart}
                price={price}
                pricePerKg={pricePerKg}
                promotionPrice={promotionPrice}
                promotionPricePerKg={promotionPricePerKg}
              />
              <AddProduct
                minimum={minimum}
                productId={productId}
                unit={unit}
                weight={weight}
              />
              <Text size="xs">
                1 unidade = aprox.
                {weight > 1000 ? `${weight / 1000}kg` : `${weight}g`}
              </Text>
              {breakpoint ? (
                <Text align="justify">
                  {description?.split('\r\n\r\n').map((text) => (
                    <Text pb={'md'} key={text}>
                      {text}
                    </Text>
                  ))}
                </Text>
              ) : null}
            </Stack>
          </Group>
          <Tabs
            color={'green'}
            pt={breakpoint ? '40px' : 0}
            px={breakpoint ? 'xl' : 0}
            defaultValue="description"
          >
            <Tabs.List>
              <Tabs.Tab value="description">
                <Title order={3}>Descrição</Title>
              </Tabs.Tab>
              <Tabs.Tab value="gallery">
                <Title order={3}>Galeria</Title>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="description" pt="xs">
              <Text align="justify">
                {description?.split('\r\n\r\n').map((text) => (
                  <Text pb={'md'} key={text}>
                    {text}
                  </Text>
                ))}
              </Text>
              <Table striped style={{ width: breakpoint ? '30%' : '100%' }}>
                <tbody>
                  {attributes?.map(({ attribute_name, attribute_value }) => (
                    <tr key={attribute_value}>
                      <td>{attribute_name}</td>
                      <td>{attribute_value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tabs.Panel>

            <Tabs.Panel value="gallery" pt="xs">
              <Group>
                {images?.map((url, index) => (
                  <Image
                    key={url || index}
                    style={{ width: '150px' }}
                    src={url}
                    alt={name}
                  />
                ))}
              </Group>
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </Container>
    </>
  );
};
