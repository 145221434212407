import { Route, Routes } from 'react-router-dom';

import { Cart } from './Cart';

export const CartRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Cart />} path="/" />
    </Routes>
  );
};
