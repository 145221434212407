import { Button, Group } from '@mantine/core';
import { Link } from 'react-router-dom';

type Props = {
  active: number;
  nextStep: () => Promise<void>;
  prevStep: () => void;
  isNextDisabled?: boolean;
};
export const ActionButtons = ({
  active,
  nextStep,
  prevStep,
  isNextDisabled,
}: Props) => {
  return active === 5 ? (
    <Group position="center" mt="xl">
      <Button component={Link} to="/" variant="default">
        Voltar à página principal
      </Button>
    </Group>
  ) : (
    <Group position="center" mt="xl">
      <Button variant="default" onClick={prevStep}>
        Anterior
      </Button>
      <Button disabled={isNextDisabled} color="dark" onClick={nextStep}>
        {active === 4 ? 'Finalizar' : 'Seguinte'}
      </Button>
    </Group>
  );
};
