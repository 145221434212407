import { Anchor, Center, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

export const EmptyCart = () => {
  return (
    <Center py="40px">
      <Stack align={'center'}>
        <i
          className="icon-basket"
          style={{
            fontSize: '80px',
          }}
        />
        <Stack spacing={0} align="center">
          <Text>Não tem nenhum produto no carrinho.</Text>
          <Text>
            <Anchor component={Link} color={'green'} to="/loja">
              Visite a loja
            </Anchor>{' '}
            para adicionar produtos.
          </Text>
        </Stack>
      </Stack>
    </Center>
  );
};
