import { Alert, Anchor, Table, Text } from '@mantine/core';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import slugify from 'react-slugify';

import { useVoucher } from '@/hooks/useVoucher';
import { useCartStore } from '@/store/cart';
import { useCheckoutStore } from '@/store/checkout';

import styles from './confirmation.module.scss';

export const Confirmation = () => {
  const {
    cart: { products, subTotal, totalAmount },
    voucher,
  } = useCartStore();
  useVoucher();
  const { priceValue } = useCheckoutStore();

  return (
    <>
      <Table
        withBorder
        mt="md"
        captionSide="top"
        withColumnBorders
        horizontalSpacing="xs"
      >
        <caption style={{ color: 'black', fontWeight: 'bold' }}>
          Resumo da encomenda
        </caption>
        <thead>
          <tr>
            <th className={styles.textLeft}>Nome do produto</th>
            <th className={styles.textRight}>Quantidade</th>
            <th className={styles.textRight}>Preço</th>
            <th className={styles.textRight}>Total</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.productId}>
              <td className={styles.textLeft}>
                <Anchor
                  color={'dark'}
                  component={Link}
                  size="sm"
                  to={`/produto/${slugify(product.name)}`}
                  target="_blank"
                >
                  {parse(DOMPurify.sanitize(product.name))}
                </Anchor>
              </td>
              <td className={styles.textRight}>{product.quantity}</td>

              <td className={styles.textRight}>
                {Number(product.price).toFixed(2)}€
              </td>
              <td className={styles.textRight}>
                {Number(product.totalValue).toFixed(2)}€
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={3} className={styles.textRight}>
              <Text weight={'bold'}>Sub-total</Text>
            </td>
            <td className={styles.textRight}>{`${(subTotal || 0).toFixed(
              2
            )}€`}</td>
          </tr>
          {voucher.code ? (
            <tr>
              <td colSpan={3} className={styles.textRight}>
                <Text weight={'bold'}>Voucher ({voucher.code})</Text>
              </td>
              <td className={styles.textRight}>{`-${(
                voucher.amount || 0
              ).toFixed(2)}€`}</td>
            </tr>
          ) : null}
          <tr>
            <td colSpan={3} className={styles.textRight}>
              <Text weight={'bold'}>Valor de entrega mínima</Text>
            </td>
            <td className={styles.textRight}>{`${priceValue}€`}</td>
          </tr>
          <tr>
            <td colSpan={3} className={styles.textRight}>
              <Text weight={'bold'}>Total</Text>
            </td>
            <td className={styles.textRight}>{`${(
              (totalAmount || 0) - (voucher.amount || 0)
            ).toFixed(2)}€`}</td>
          </tr>
        </tbody>
      </Table>
      <Alert style={{ textAlign: 'center' }} color="dark" variant="filled">
        <Text component="span" weight={'bold'}>
          Caso o valor do carrinho seja inferior ao valor da entrega minima,
          este será acrescido até o igualar.
        </Text>
      </Alert>
    </>
  );
};
