import {
  Button,
  Card,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useVoucher } from '@/hooks/useVoucher';
import { useCartStore } from '@/store/cart';

type Props = {
  totalAmount: number;
};

export const CartSummary = ({ totalAmount }: Props) => {
  const { voucher, cart } = useCartStore();
  const [coupon, setCoupon] = useState<string>(voucher?.code || '');
  const [isVoucherVisible, setIsVoucherVisible] = useState(false);
  const { getVoucher } = useVoucher();
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  const handleApplyClick = async () => {
    getVoucher({ coupon });
  };

  useEffect(() => {
    setIsVoucherVisible(!!voucher.code);
  }, [voucher]);

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Title order={3}>TOTAL NO CARRINHO</Title>
      <Group py="xl" position="apart">
        <Title order={6}>Subtotal</Title>
        <Text>{Math.max(0, Number(cart.subTotal)).toFixed(2)}€</Text>
      </Group>
      <Divider />
      {isVoucherVisible ? (
        <>
          <Group py="xl" position="apart">
            <Title order={6}>Vale ({voucher.code})</Title>
            <Text>-{Number(voucher.amount || 0).toFixed(2)}€</Text>
          </Group>
          <Divider />{' '}
        </>
      ) : null}
      <Group grow align={'center'} py="xl" position="apart">
        <Title order={6}>Envio</Title>
        <Stack spacing="xs">
          <Text size="sm">Entrega ao Domicílio</Text>
        </Stack>
      </Group>
      <Divider />
      <Stack>
        <Group py="xl" position="apart">
          <Title order={5}>Total</Title>
          <Text>
            {Math.max(0, Number(totalAmount - (voucher?.amount || 0))).toFixed(
              2
            )}
            €
          </Text>
        </Group>
      </Stack>
      <Divider />
      <Stack>
        <Group spacing={0} pt="xl" grow={breakpoint} position="apart">
          <Stack spacing={0}>
            <Title order={5}>Vale de desconto</Title>
            {voucher.error ? (
              <Text size={'xs'} color="red">
                {voucher.error}
              </Text>
            ) : null}
          </Stack>
          <TextInput
            style={{ flexGrow: voucher.error ? 1 : 0 }}
            value={coupon}
            onChange={({ target: { value } }) => setCoupon(value)}
          />
        </Group>
        <Group position="right">
          <Button onClick={handleApplyClick} color="dark">
            Aplicar
          </Button>
        </Group>
        <Text size={'xs'} align="justify">
          <b>Nota:</b> o valor final do seu pedido é meramente indicativo.
          Comercializamos os nossos produtos de uma forma simples, realista e
          honesta. Assim, antes da entrega todos os artigos são sempre pesados e
          facturados com o seu valor real para que o que paga seja efectivamente
          o que recebe em sua casa ou no seu escritório.
        </Text>
        <Button component={Link} to="/checkout" color="dark">
          Finalizar compra
        </Button>
      </Stack>
    </Card>
  );
};
