import create from 'zustand';

type CartStore = {
  cart: Cart;
  setCart: (cartData: Cart) => void;
  voucher: Voucher;
  setVoucher: (voucherData: Voucher) => void;
  resetVoucher: () => void;
  getProductById: (productId: number) => CartProduct | undefined;
};

export const useCartStore = create<CartStore>((set, get) => ({
  cart: { products: [], totalAmount: 0, totalProducts: 0 },
  setCart: (cartData: Cart) => {
    const subTotal = cartData.products.reduce((agg, val) => {
      return agg + val.totalValue;
    }, 0);
    set({
      cart: {
        ...cartData,
        subTotal,
      },
    });
  },
  getProductById: (productId: number) => {
    return get().cart.products.find(
      (product) => product.productId === productId
    );
  },
  voucher: { name: '', amount: 0, error: undefined, code: '', minSubTotal: 0 },
  setVoucher: (voucherData: Voucher) => {
    set({ voucher: { ...voucherData } });
  },
  resetVoucher: () => {
    set({
      voucher: {
        name: '',
        amount: 0,
        error: undefined,
        code: '',
        minSubTotal: 0,
      },
    });
  },
}));
