import { Route, Routes } from 'react-router-dom';

import { Contacts } from './Contacts';

export const ContactsRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Contacts />} path="/" />
    </Routes>
  );
};
