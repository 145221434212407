import { Modal, Button, Group, Stack } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  isOpened: boolean;
  onConfirm: () => void;
  onClose: () => void;
  children: ReactNode;
  title: string;
};

export const ConfirmationModal = ({
  isOpened,
  onConfirm,
  onClose,
  children,
  title,
}: Props) => {
  return (
    <Modal centered opened={isOpened} onClose={onClose} title={title}>
      <Stack spacing="xl">
        {children}
        <Group position="center">
          <Button color="gray" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="dark" onClick={onConfirm}>
            Confirmar
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
