import { UseMutateFunction, useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';

import { CouponApiResponse } from './api';

const url = 'index.php?route=api/coupon/coupon';

type CouponInfo = {
  isLoading: boolean;
  getVoucher: UseMutateFunction<
    CouponApiResponse,
    unknown,
    { coupon: string },
    unknown
  >;
  data: CouponApiResponse | undefined;
  error: unknown;
};

const useGetCoupon = (): CouponInfo => {
  const { mutate, isLoading, data, error } = useMutation<
    CouponApiResponse,
    unknown,
    { coupon: string }
  >(async ({ coupon }): Promise<CouponApiResponse> => {
    const { data } = await axiosInstance.post<CouponApiResponse>(url, {
      coupon,
    });
    return data;
  });

  return {
    getVoucher: mutate,
    isLoading,
    data,
    error,
  };
};
export default useGetCoupon;
