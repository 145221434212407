import { useMemo } from 'react';

import useAddresses from '@/api/useAddresses';

type Props = {
  routeName: string;
  addressId: string;
};
export const RouteInfo = ({ routeName, addressId }: Props) => {
  const { addresses } = useAddresses();
  const selectedAddress = useMemo(
    () => addresses.find((address) => address.addressId === addressId),
    [addressId, addresses]
  );
  return (
    <caption style={{ color: 'black', fontWeight: 'bold' }}>
      Valores mínimos de encomenda para {routeName} ({selectedAddress?.postcode}
      )
    </caption>
  );
};
