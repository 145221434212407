import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';
import { useCartStore } from '@/store/cart';

import { GetCartSummaryResponse } from './api';

const url = 'index.php?route=api/cart/getCartSummary';

type CartSummaryInfo = {
  isLoading: boolean;
  cart?: Cart;
  error: unknown;
  refetch?: () => void;
};

const useGetCart = (): CartSummaryInfo => {
  const { setCart } = useCartStore();
  const { data, isLoading, isFetching, error, refetch } =
    useQuery<GetCartSummaryResponse>(
      ['useGetCartSummary'],
      async () => {
        const { data } = await axiosInstance.get<GetCartSummaryResponse>(url);
        return data;
      },
      {
        onSuccess: (result: GetCartSummaryResponse) => {
          const { cart } = result;
          if (cart !== null) setCart({ ...cart });
        },
      }
    );

  if (!data?.cart || isLoading) return { isLoading, error };

  const cart = {
    totalAmount: data.cart.totalAmount,
    totalProducts: data.cart.totalProducts,
    products: data.cart.products,
  };

  return { cart, isLoading: isLoading || isFetching, error, refetch };
};
export default useGetCart;
