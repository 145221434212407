import {
  DeliveryPricesInfo,
  IndexedWeekDayPrice,
  WeekDayPrice,
} from '@/api/useDeliveryPrices';
import { workingDays } from '@/utils/constants';

import { PriceCell } from '../PriceCell';
import { ScheduleTimeCell } from '../ScheduleTimeCell';

type Props = {
  priceCustom?: DeliveryPricesInfo;
  priceInfo?: DeliveryPricesInfo;
};

export const Row = ({ priceInfo, priceCustom }: Props) => {
  if (!priceCustom) {
    const {
      scheduleTime,
      weekDayPrice,
    }: { scheduleTime: string; weekDayPrice: IndexedWeekDayPrice } =
      priceInfo as DeliveryPricesInfo;
    return (
      <tr>
        <ScheduleTimeCell scheduleTime={scheduleTime} />
        {(workingDays || []).map((key) => (
          <PriceCell
            key={key}
            price={weekDayPrice[key].price}
            idPrice={weekDayPrice[key].idPrice}
          />
        ))}
      </tr>
    );
  }
  const { price, idPrice } = priceCustom.weekDayPrice as WeekDayPrice;
  return (
    <tr>
      <ScheduleTimeCell scheduleTime="Agendamento personalizado" />
      <PriceCell mergeCells price={price} idPrice={idPrice} />
    </tr>
  );
};
