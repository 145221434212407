import { Badge, Group, Stack, Text } from '@mantine/core';
import { format } from 'date-fns';

import { ProductUI } from '@/api/useGetProducts';

type Props = {
  promotionPrice: ProductUI['promotionPrice'];
  promotionPricePerKg: ProductUI['promotionPricePerKg'];
  pricePerKg: ProductUI['pricePerKg'];
  price: ProductUI['price'];
  dateStart: ProductUI['dateStart'];
  dateEnd: ProductUI['dateEnd'];
  unit: ProductUI['unit'];
};

type PromotionBadgeProps = {
  dateStart: ProductUI['dateStart'];
  dateEnd: ProductUI['dateEnd'];
};
const PromotionBadge = ({ dateStart, dateEnd }: PromotionBadgeProps) => {
  if (!dateStart || !dateEnd) return null;
  return (
    <Badge
      style={{ width: 'fit-content' }}
      mt="md"
      variant="filled"
      color="dark"
    >
      <Text weight={'bold'}>
        Promoção válida de {format(dateStart, 'dd/LL/yyyy')} a{' '}
        {format(dateEnd, 'dd/LL/yyyy')}
      </Text>
    </Badge>
  );
};

export const PriceInfo = ({
  promotionPrice,
  promotionPricePerKg,
  pricePerKg,
  price,
  dateStart,
  dateEnd,
  unit,
}: Props) => {
  const priceToShowPerKg =
    promotionPrice !== 0 ? promotionPricePerKg : pricePerKg;

  const priceToShowByUn = promotionPrice !== 0 ? promotionPrice : price;

  const highlighPrice =
    unit.toUpperCase() !== 'KG'
      ? `${Number(priceToShowByUn).toFixed(2)}€/Un`
      : `${Number(priceToShowPerKg).toFixed(2)}€/KG`;
  const smallPrice =
    unit.toUpperCase() !== 'KG'
      ? `${Number(priceToShowPerKg).toFixed(2)}€/KG`
      : `${Number(priceToShowByUn).toFixed(2)}€/Un`;

  return (
    <Stack spacing={0}>
      <Group align={'center'}>
        <Text pt={'md'} size="xl" weight="bold">
          {highlighPrice}
        </Text>

        {promotionPrice ? (
          <Text pt="md" size="sm" style={{ textDecoration: 'line-through' }}>
            {unit.toUpperCase() === 'KG'
              ? `${Number(pricePerKg).toFixed(2)}€/KG`
              : `${Number(price).toFixed(2)}€/Un`}
          </Text>
        ) : null}
      </Group>

      <Group align={'center'} spacing={0}>
        <Text size="sm">{smallPrice}</Text>
      </Group>
      <PromotionBadge dateStart={dateStart} dateEnd={dateEnd} />
    </Stack>
  );
};
