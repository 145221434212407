import { Group, Stack, Text } from '@mantine/core';

import { UserInvoice } from '@/api/useUserInvoiceDetails';

type Props = {
  userInvoiceInfo: UserInvoice;
};

export const InvoiceInformation = ({ userInvoiceInfo }: Props) => {
  const {
    nif,
    firstName,
    lastName,
    company,
    street,
    other,
    zipCode1,
    zipCode2,
    city,
  } = userInvoiceInfo;
  return (
    <Stack>
      <Group spacing={4}>
        <Text weight={'bold'}>Número de contribuinte:</Text>
        <Text>{nif}</Text>
      </Group>
      <Group spacing={4} align="flex-start">
        <Text weight={'bold'}>Morada:</Text>
        {/* <Stack spacing="xs"> */}
        <Text> {`${firstName} ${lastName},`} </Text>
        {company && <Text> {company}</Text>}
        <Text> {`${street} ${other}`} </Text>
        <Text> {`${zipCode1}-${zipCode2} ${city}`}</Text>
        {/* </Stack> */}
      </Group>
    </Stack>
  );
};
