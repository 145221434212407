import {
  Button,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { debounce } from 'lodash';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import useCart from '@/api/useCart';
import { NumberInputWithButtons } from '@/components/NumberInputWithButtons';

type Props = {
  products: CartProduct[];
  onClearCart: () => void;
};

export const Mobile = ({ products = [], onClearCart }: Props) => {
  const { updateCart, isClearing } = useCart();
  const [openedTooltipForProduct, setOpenedTooltipForProduct] = useState(-1);

  const handleClearCart = () => onClearCart && onClearCart();

  const updateValue = (
    minimum: number,
    newQuantity: number,
    productId: number
  ) => {
    if (newQuantity < minimum) return;
    else updateCart({ productId: `${productId}`, quantity: newQuantity });
  };

  return (
    <>
      {products.map((product) => (
        <Fragment key={product.productId}>
          <Group align="start">
            <Image
              radius={'md'}
              style={{ width: '80px' }}
              src={product.thumb}
              alt={product.name}
            />
            <Stack spacing={0} style={{ maxWidth: '70%', flexGrow: 1 }}>
              <Group position="apart">
                <Title order={4}>
                  {parse(DOMPurify.sanitize(product.name))}
                  {product.minimum > 1 ? (
                    <Tooltip
                      onMouseLeave={() => setOpenedTooltipForProduct(-1)}
                      opened={product.productId === openedTooltipForProduct}
                      label={`Quantidade mínima: ${product.minimum}`}
                    >
                      <i
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() =>
                          setOpenedTooltipForProduct(product.productId)
                        }
                        className="icon-info-circled"
                      />
                    </Tooltip>
                  ) : null}
                </Title>
                <Button
                  onClick={() =>
                    updateCart({
                      productId: `${product.productId}`,
                      quantity: 0,
                    })
                  }
                  color={'dark'}
                  size="xs"
                  variant="subtle"
                >
                  X
                </Button>
              </Group>
              <Group pb={'xs'} position="apart">
                <Text size="sm" weight={'bold'}>
                  Preço
                </Text>
                <Text size="sm" color="gray">
                  {Number(product.price).toFixed(2)}€
                </Text>
              </Group>
              <Divider pt="xs" />
              <Group pb={'xs'} position="apart">
                <Text size="sm" weight={'bold'}>
                  Unidades
                </Text>
                <NumberInputWithButtons
                  buttonSize={36}
                  inputWidth={60}
                  minimum={product.minimum}
                  value={product.quantity}
                  onChange={debounce(
                    (newQuantity) =>
                      updateValue(
                        product.minimum,
                        newQuantity,
                        product.productId
                      ),
                    300
                  )}
                />
              </Group>
              <Divider pt="xs" />
              <Group position="apart">
                <Text size="sm" weight={'bold'} color="green">
                  Subtotal
                </Text>
                <Text>{Number(product.totalValue).toFixed(2)}€</Text>
              </Group>
            </Stack>
          </Group>
          {products.at(-1) !== product ? <Divider mt="xl" p={'sm'} /> : null}
        </Fragment>
      ))}
      <Divider my={'xl'} />
      <Group pb="xl" grow position="apart">
        <Button component={Link} to="/loja" color="green" size="xs">
          Continuar a comprar
        </Button>
        <Button
          disabled={isClearing}
          loading={isClearing}
          onClick={handleClearCart}
          color="dark"
          size="xs"
        >
          Limpar carrinho
        </Button>
      </Group>
    </>
  );
};
