import { Anchor, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom';

export const ThankYou = () => {
  const theme = useMantineTheme();
  return (
    <Stack align={'center'}>
      <i
        className="icon-ok-circled"
        style={{
          color: theme.colors.green[6],
          fontSize: 8 * theme.fontSizes.xl,
        }}
      />
      <Stack align="center" spacing={'xs'}>
        <Title>Obrigado</Title>
        <Text>O seu pedido foi processado com sucesso! </Text>
        <Text>
          Se tem dúvidas ou perguntas a colocar por favor entre em contato com o{' '}
          <Anchor
            component={Link}
            color="green"
            to="/https://frutada5a.pt/contacto"
          >
            proprietário da Loja.
          </Anchor>
        </Text>

        <Text>Obrigado por fazer compras on-line connosco!</Text>
      </Stack>
    </Stack>
  );
};
