import {
  Card,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAddresses from '@/api/useAddresses';
import { useCheckoutStore } from '@/store/checkout';

import { ShippingAddressCard } from './ShippingAddressCard';

export const ShippingAddresses = () => {
  const { addressId, setAddressId } = useCheckoutStore();
  const navigate = useNavigate();
  const { addresses, isLoading } = useAddresses();
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  useEffect(() => {
    if (addresses && addresses.length === 1) {
      setAddressId(addresses[0].addressId);
    }
  }, [addresses, setAddressId]);

  if (isLoading) return <LoadingOverlay visible={isLoading}></LoadingOverlay>;

  if (!addresses || addresses.length === 0) {
    return <div>Não existem moradas associadas.</div>;
  }
  const handleOnSelect = (newAddressId: string) => {
    setAddressId(addressId === newAddressId ? '' : newAddressId);
  };

  return (
    <Group spacing="md">
      {addresses.map((address) => {
        return (
          <ShippingAddressCard
            onSelect={handleOnSelect}
            selected={addressId === address.addressId}
            key={address.addressId}
            address={address}
          />
        );
      })}
      <Card
        onClick={() => navigate('/minha-conta')}
        sx={() => ({
          backgroundColor: theme.colors.green,
          color: 'white',
          cursor: 'pointer',
          width: breakpoint ? '200px' : '150px',
          height: '200px',
        })}
        key={addressId}
        shadow="sm"
        p="lg"
        radius="md"
        withBorder
      >
        <Stack justify="space-between" style={{ height: '100%' }}>
          <Stack
            spacing="xs"
            justify="center"
            align="center"
            style={{ height: '100%' }}
          >
            <Title order={1}>+</Title>
            <Text size="xs">Adicionar outra morada</Text>
          </Stack>
        </Stack>
      </Card>
    </Group>
  );
};
