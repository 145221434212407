import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';

const url = 'index.php?route=api/order/createOrder';

type OrderParams = {
  comment?: string;
  customerId?: number;
  shippingAddressId?: number;
  paymentMethod?: string;
  priceId?: number;
  voucherCode: string;
  voucherAmount: number;
};

type CreateOrderResult = {
  success: string;
  error: { [x: string]: string };
};

const useCreateOrder = () => {
  const { mutate, isLoading, data, error } = useMutation(
    async ({
      comment,
      customerId,
      shippingAddressId,
      paymentMethod,
      priceId,
      voucherAmount,
      voucherCode,
    }: OrderParams): Promise<CreateOrderResult> => {
      const { data } = await axiosInstance.post<CreateOrderResult>(url, {
        comment,
        customerId,
        shippingAddressId,
        paymentMethod,
        priceId,
        voucherAmount,
        voucherCode,
      });
      return {
        error: data.error,
        success: data.success,
      };
    }
  );

  return { createOrder: mutate, isLoading, data, error };
};

export default useCreateOrder;
