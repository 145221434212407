import {
  Center,
  Container,
  Grid,
  Loader,
  MediaQuery,
  Tabs,
} from '@mantine/core';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useUserInfo from '@/api/useUserInfo';
import { TopMenu } from '@/components/TopMenu';
import { useGlobalStore } from '@/store/global';

import { AccountAddresses } from '../components/AccountAddresses';
import { AccountDetails } from '../components/AccountDetails';

export const MyAccount = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { reset } = useGlobalStore();
  const { error, isLoading } = useUserInfo();

  useEffect(() => {
    if (isLoading) return;
    if (error) {
      reset();
      navigate('/auth', { state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  const handleLogout = () => {
    reset();
    navigate('/auth');
  };

  // eslint-disable-next-line no-constant-condition
  if (isLoading)
    return (
      <Center style={{ width: '100vw', height: '100vh' }}>
        <Loader color="dark" />
      </Center>
    );

  return (
    <>
      <TopMenu />
      <Container size="xl">
        <MediaQuery
          smallerThan="md"
          styles={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
          }}
        >
          <Grid columns={12} py="100px" px="100px">
            <Grid.Col md={12}>
              <Tabs
                styles={{ root: { overflow: 'auto' } }}
                color="dark"
                variant="pills"
                orientation="vertical"
                defaultValue="info"
              >
                <Tabs.List>
                  <Tabs.Tab value="info">Detalhes da conta</Tabs.Tab>
                  <Tabs.Tab value="addresses">Endereços</Tabs.Tab>
                  <Tabs.Tab onClick={handleLogout} value="logout">
                    Sair
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="info" pl="xl">
                  <AccountDetails />
                </Tabs.Panel>

                <Tabs.Panel value="addresses" pl="xl">
                  <AccountAddresses />
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>
          </Grid>
        </MediaQuery>
      </Container>
    </>
  );
};
