import {
  Anchor,
  Container,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { TopMenu } from '@/components/TopMenu';

export const Contacts = () => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  return (
    <>
      <TopMenu />
      <Container size="md" py="60px">
        <Stack>
          <Group position="apart" align="top" grow={breakpoint ? true : false}>
            <Stack align="center" style={{ flexGrow: breakpoint ? 0 : 1 }}>
              <i
                className="icon-location"
                style={{
                  fontSize: '50px',
                }}
              />
              <Title order={2}>Morada</Title>
              <Text weight={'bold'}>Sede</Text>
              <Text>
                Urb. Qt. S. Luiz, Lt. 59 - R/C Esq. <br />
                3140-348 PEREIRA MMV
              </Text>
              <Text weight={'bold'}>Filial</Text>
              <Text>
                Mercado Abastecedor de Coimbra
                <br />
                Pav. Núcleo Central - A | Pav. B - 26
                <br /> 3045-508 TAVEIRO
                <br />
                <br />
                <b>NIF</b> 514 472 243
                <br />
                <b>PT HF</b> 132 685
              </Text>
            </Stack>
            <Stack align="center" style={{ flexGrow: breakpoint ? 0 : 1 }}>
              <i
                className="icon-mail"
                style={{
                  fontSize: '50px',
                }}
              />
              <Title order={2}>E-mail</Title>
              <Text weight={'bold'}>Geral: </Text>
              <Text>
                <Anchor color="dark" href={'mailto:geral@frutada5a.pt'}>
                  geral@frutada5a.pt
                </Anchor>
              </Text>
              <Text weight={'bold'}>Encomendas</Text>
              <Text>
                <Anchor color="dark" href={'mailto:encomendas@frutada5a.pt'}>
                  encomendas@frutada5a.pt
                </Anchor>
              </Text>
              <Text weight={'bold'}>Candidatura espontânea</Text>
              <Text>
                <Anchor color="dark" href={'mailto:candidatura@frutada5a.pt'}>
                  candidatura@frutada5a.pt
                </Anchor>
              </Text>
            </Stack>
            <Stack align="center" style={{ flexGrow: breakpoint ? 0 : 1 }}>
              <i
                className="icon-phone"
                style={{
                  fontSize: '50px',
                }}
              />
              <Title order={2}>Telefone</Title>
              <Text>
                <Anchor color="dark" href={'tel:+351 968 675 057'}>
                  968 675 057
                </Anchor>
              </Text>
              <Text size="xs">Chamada para a rede móvel nacional</Text>
              <i
                className="icon-globe"
                style={{
                  fontSize: '50px',
                  marginTop: '20px',
                }}
              />
              <Group>
                <Anchor color="dark" href="https://www.fb.com/Frutada5a">
                  <i className="icon-facebook" style={{ fontSize: '30px' }} />
                </Anchor>
                <Anchor
                  color="dark"
                  href="https://api.whatsapp.com/send?phone=351968675057"
                >
                  <i className="icon-whatsapp" style={{ fontSize: '30px' }} />
                </Anchor>
                <Anchor
                  color="dark"
                  href="https://www.instagram.com/frutada5a/"
                >
                  <i className="icon-instagram" style={{ fontSize: '30px' }} />
                </Anchor>
              </Group>
            </Stack>
          </Group>
          <iframe
            title="mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.862629557941!2d-8.515787030658053!3d40.19142581687151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd225761e6d6141f%3A0x95914561986a1ee7!2sFRUTA%20DA%205%C2%AA!5e0!3m2!1sen!2spt!4v1658338112600!5m2!1sen!2spt"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
        </Stack>
      </Container>
    </>
  );
};
