import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';

import { DeliveryPricesResponse } from './api';

const url = 'index.php?route=checkout/shipping_method/deliveryPrices';

export type WeekDayPrice = {
  price: number;
  idPrice: number;
};

export type IndexedWeekDayPrice = {
  [x: number]: WeekDayPrice;
};

export type DeliveryPricesInfo = {
  scheduleTime: string;
  weekDayPrice: IndexedWeekDayPrice | WeekDayPrice;
};

export type DeliveryPrices = {
  [x: number]: DeliveryPricesInfo;
};

type HookParams = {
  postCode: string;
};

type Hook = (params: HookParams) => {
  isLoading: boolean;
  deliveryPrices: DeliveryPrices;
  error: unknown;
  routeName: string;
};

const useDeliveryPrices: Hook = ({ postCode }) => {
  const { data, isLoading, error } = useQuery(
    ['useDeliveryPrices', postCode],
    async () => {
      const { data } = await axiosInstance.post<DeliveryPricesResponse>(url, {
        postCode,
      });
      return data;
    },
    { enabled: postCode.length > 0 }
  );

  return {
    routeName: data?.routeName || '',
    deliveryPrices: data?.prices as DeliveryPrices,
    isLoading,
    error: data?.error || error,
  };
};
export default useDeliveryPrices;
