import { Group, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ProductUI } from '@/api/useGetProducts';
import { ProductCard } from '@/components/ProductCard';

type Props = {
  products: ProductUI[];
};

export const ProductsList = ({ products }: Props) => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  return (
    <Group pl={!breakpoint ? 0 : 'md'}>
      {(products || []).map(
        ({
          name,
          productId,
          url,
          promotionPrice,
          price,
          unit,
          pricePerKg,
          promotionPricePerKg,
          promotionValue,
          dateEnd,
          weight,
          minimum,
        }) => (
          <ProductCard
            key={productId}
            productId={productId}
            url={url}
            price={price}
            name={name}
            promotionPrice={promotionPrice}
            unit={unit}
            pricePerKg={pricePerKg}
            promotionPricePerKg={promotionPricePerKg}
            promotionValue={promotionValue}
            promotionDateEnd={dateEnd}
            weight={weight}
            minimum={minimum}
            useFixedWith
          />
        )
      )}
    </Group>
  );
};
