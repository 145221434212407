import { Image, MediaQuery, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import logo from '@/assets/logo.png';

export const Logo = () => {
  return (
    <>
      <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
        <Link to="/" style={{ position: 'absolute', top: 0, zIndex: 1 }}>
          <Image
            height={150}
            width={150}
            src={logo}
            alt="Logo"
            withPlaceholder
            placeholder={<Text align="center">Logo</Text>}
          />
        </Link>
      </MediaQuery>
      <MediaQuery largerThan="md" styles={{ display: 'none' }}>
        <Link to="/" style={{ position: 'absolute', top: '50px', zIndex: 1 }}>
          <Image
            height={80}
            width={80}
            src={logo}
            alt="Logo"
            withPlaceholder
            placeholder={<Text align="center">Logo</Text>}
          />
        </Link>
      </MediaQuery>
    </>
  );
};
