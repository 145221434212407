import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Container,
  Group,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useUpdateCommonDetails from '@/api/useUpdateCommonDetails';
import useUserInfo from '@/api/useUserInfo';
import { REQUIRED_FIELD } from '@/utils/constants';

type FormData = {
  firstName: string;
  lastName: string;
  telephone: string;
};

const schema = yup
  .object({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    telephone: yup
      .string()
      .matches(
        /^(9[1236]\d{7}|2\d{8})$/,
        'Número inválido. Exemplos de números válidos: 919999999 ou 219999999'
      ),
  })
  .required();

const FormInputs = ({ children }: { children: ReactNode }) => {
  const theme = useMantineTheme();

  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.xs}px)`);

  if (breakpoint) {
    return (
      <Group grow spacing="xl" align={'start'}>
        {children}
      </Group>
    );
  }
  return <Stack spacing="md">{children}</Stack>;
};

export const CommonDetails = () => {
  const { userInfo, isLoading } = useUserInfo();

  const { updateCommonDetails, isSaving } = useUpdateCommonDetails();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    watch,
    setError,
  } = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const { firstName, lastName, telephone } = watch();

  useEffect(() => {
    if (isLoading || !userInfo) return;

    const { telephone, firstName, lastName } = userInfo || {};
    reset({ firstName, lastName, telephone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, userInfo?.telephone, userInfo?.firstName, userInfo?.lastName]);

  const isFormValid = Object.keys(errors).length === 0 && isDirty;

  const onSubmit = () => {
    updateCommonDetails(
      { firstName, lastName, telephone },
      {
        onSuccess: () => {
          showNotification({
            title: 'Sucesso',
            message: 'Alterações efetuadas com sucesso.',
            color: 'green',
          });
        },
        onError: ({ response }) => {
          setError('firstName', { message: response?.data.firstname });
          setError('lastName', { message: response?.data.lastname });
          setError('telephone', { message: response?.data.telephone });
          showNotification({
            title: 'Erro',
            message: 'Ocorreu um erro a guardar as alterações',
            color: 'red',
          });
        },
      }
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container py="xl">
        <Stack spacing="xl">
          <FormInputs>
            <TextInput
              error={errors.firstName?.message}
              placeholder="nome"
              label="Nome:"
              required
              {...register('firstName')}
            />
            <TextInput
              error={errors.lastName?.message}
              placeholder="apelidos"
              label="Apelidos:"
              required
              {...register('lastName')}
            />
          </FormInputs>

          <TextInput
            error={errors.telephone?.message}
            placeholder="telefone"
            label="Telefone:"
            {...register('telephone')}
          />
          <Stack align="end">
            <Button
              disabled={!isFormValid || isSaving}
              loading={isSaving}
              type="submit"
              color="dark"
            >
              Guardar
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
