import {
  Divider,
  Grid,
  Group,
  Menu,
  Text,
  TextInput,
  useMantineTheme,
  Stack,
  MediaQuery,
  Badge,
} from '@mantine/core';
import { debounce } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useGetCartSummary from '@/api/useGetCartSummary';
import { useCartStore } from '@/store/cart';

import { Logo } from './Logo';

export const TopMenu = (): JSX.Element => {
  const theme = useMantineTheme();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  useGetCartSummary();
  const {
    cart: { subTotal, totalProducts },
  } = useCartStore();

  const handleSearchNameChange: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { key, target } = event;
    const value = (target as HTMLInputElement).value;
    setSearchText(value);
    if ((key === 'Enter' && value.length > 2) || value.length === 0) {
      navigate(`/loja?search=${value}`);
    }
  };

  const handleCartClick = () => {
    navigate('/carrinho', { replace: true });
  };

  return (
    <>
      <MediaQuery
        smallerThan="md"
        styles={{
          paddingLeft: theme.spacing.lg,
          paddingRight: theme.spacing.lg,
        }}
      >
        <Grid
          style={{
            position: 'sticky',
            inset: 0,
            backgroundColor: 'white',
            zIndex: 99,
          }}
          gutter={0}
          px={100}
          py={theme.spacing.lg}
          align="center"
        >
          <Grid.Col md={12}>
            <Group>
              <Logo />
              <MediaQuery smallerThan="md" styles={{ paddingLeft: 0 }}>
                <Group
                  noWrap
                  pl="150px"
                  style={{ flexGrow: 1 }}
                  spacing={0}
                  position="apart"
                >
                  <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                    <Group align="center" spacing={40} mx="xl">
                      <Menu withArrow={false} trigger="hover" position="bottom">
                        <Menu.Target>
                          <Group
                            onClick={() => navigate('/loja')}
                            spacing={0}
                            sx={() => ({
                              cursor: 'pointer',
                              transition: 'filter 0.5s',
                              '&:hover': {
                                filter: 'invert(0.5)',
                              },
                            })}
                          >
                            <Text size={'xl'} weight={'bold'}>
                              Loja
                            </Text>
                          </Group>
                        </Menu.Target>
                      </Menu>
                      <Menu withArrow trigger="hover" position="bottom">
                        <Menu.Target>
                          <Group
                            onClick={() => navigate('/quem-somos')}
                            spacing={0}
                            sx={() => ({
                              cursor: 'pointer',
                              transition: 'filter 0.5s',
                              '&:hover': {
                                filter: 'invert(0.5)',
                              },
                            })}
                          >
                            <Text>Quem somos</Text>
                          </Group>
                        </Menu.Target>
                      </Menu>
                      <Menu withArrow trigger="hover" position="bottom">
                        <Menu.Target>
                          <Group
                            onClick={() => navigate('/contactos')}
                            spacing={0}
                            sx={() => ({
                              cursor: 'pointer',
                              transition: 'filter 0.5s',
                              '&:hover': {
                                filter: 'invert(0.5)',
                              },
                            })}
                          >
                            <Text>Contactos</Text>
                          </Group>
                        </Menu.Target>
                      </Menu>
                      <Menu withArrow trigger="hover" position="bottom">
                        <Menu.Target>
                          <Group
                            onClick={() => navigate('/perguntas-frequentes')}
                            spacing={0}
                            sx={() => ({
                              cursor: 'pointer',
                              transition: 'filter 0.5s',
                              '&:hover': {
                                filter: 'invert(0.5)',
                              },
                            })}
                          >
                            <Text>FAQ</Text>
                          </Group>
                        </Menu.Target>
                      </Menu>
                    </Group>
                  </MediaQuery>
                  <MediaQuery largerThan="md" styles={{ width: '50%' }}>
                    <MediaQuery smallerThan="md" styles={{ width: '100%' }}>
                      <Group align="center" position="right">
                        <MediaQuery
                          largerThan="md"
                          styles={{ display: 'none' }}
                        >
                          <Text
                            onClick={() => navigate('/loja')}
                            sx={() => ({
                              cursor: 'pointer',
                              transition: 'filter 0.5s',
                              '&:hover': {
                                filter: 'invert(0.5)',
                              },
                            })}
                            size={'xl'}
                            weight={'bold'}
                          >
                            Loja
                          </Text>
                        </MediaQuery>
                        <TextInput
                          value={searchText}
                          enterKeyHint="search"
                          style={{ flexGrow: 1, width: '60%' }}
                          rightSection={
                            <i
                              tabIndex={-1}
                              role="button"
                              onKeyDown={() =>
                                searchText.length > 2 &&
                                navigate(`/loja?search=${searchText}`)
                              }
                              onClick={() =>
                                searchText.length > 2 &&
                                navigate(`/loja?search=${searchText}`)
                              }
                              className="icon-search"
                              style={{ fontSize: theme.fontSizes.md }}
                            />
                          }
                          onChange={(event) =>
                            setSearchText(event.target.value)
                          }
                          placeholder="Pesquisar produtos"
                          aria-label="pesquisar produtos"
                          onKeyDown={debounce(handleSearchNameChange, 300)}
                        ></TextInput>

                        <Group
                          style={{ cursor: 'pointer' }}
                          onClick={handleCartClick}
                        >
                          <MediaQuery
                            smallerThan="md"
                            styles={{ display: 'none' }}
                          >
                            <i
                              className="icon-basket"
                              style={{
                                fontSize: theme.fontSizes.md * 2,
                              }}
                            />
                          </MediaQuery>
                          <MediaQuery
                            largerThan="md"
                            styles={{ display: 'none' }}
                          >
                            <div style={{ position: 'relative' }}>
                              <i
                                className="icon-basket"
                                style={{
                                  fontSize: theme.fontSizes.md * 2,
                                }}
                              />
                              <Badge
                                size="xs"
                                variant="filled"
                                color={'green'}
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  right: -10,
                                }}
                              >
                                {totalProducts}
                              </Badge>
                            </div>
                          </MediaQuery>
                          <MediaQuery
                            smallerThan="md"
                            styles={{ display: 'none' }}
                          >
                            <Divider
                              orientation="vertical"
                              color="dark"
                              style={{ height: 'inherit' }}
                            />
                          </MediaQuery>
                          <MediaQuery
                            smallerThan="md"
                            styles={{ display: 'none' }}
                          >
                            <Stack spacing={0}>
                              <Text>{totalProducts || 0} produto(s)</Text>
                              <Text>{Number(subTotal || 0).toFixed(2)}€</Text>
                            </Stack>
                          </MediaQuery>
                        </Group>
                      </Group>
                    </MediaQuery>
                  </MediaQuery>
                </Group>
              </MediaQuery>
            </Group>
          </Grid.Col>
        </Grid>
      </MediaQuery>
      <Divider sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })} />
    </>
  );
};
