import { Route, Routes } from 'react-router-dom';

import { TermsAndConditions } from './TermsAndConditions';

export const TermsAndConditionsRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<TermsAndConditions />} path="/" />
    </Routes>
  );
};
