import {
  Burger,
  createStyles,
  Drawer,
  Stack,
  Title,
  Divider,
  Image,
  MediaQuery,
} from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '@/assets/logo.png';

const useStyles = createStyles((theme) => ({
  burger: {
    position: 'absolute',
    top: '5px',
    left: theme.spacing.xs,
  },
}));

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <>
      <MediaQuery
        largerThan="md"
        styles={{
          display: 'none',
        }}
      >
        <Burger
          size="sm"
          className={classes.burger}
          opened={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        />
      </MediaQuery>
      <Drawer
        opened={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        padding="xl"
        size="80%"
        title={<Image height={80} width={80} src={logo} alt="Logo" pl="75px" />}
      >
        <Stack spacing={'xs'}>
          <Title
            order={5}
            onClick={() => {
              navigate('/');
              setIsOpen(!isOpen);
            }}
          >
            INÍCIO
          </Title>
          <Divider />
          <Title
            order={5}
            onClick={() => {
              navigate('/quem-somos');
              setIsOpen(!isOpen);
            }}
          >
            QUEM SOMOS
          </Title>
          <Divider />

          <Title
            order={5}
            onClick={() => {
              navigate('/contactos');
              setIsOpen(!isOpen);
            }}
          >
            CONTACTOS
          </Title>
          <Divider />
          <Title
            order={5}
            onClick={() => {
              navigate('/loja');
              setIsOpen(!isOpen);
            }}
          >
            LOJA ONLINE
          </Title>
          <Divider />
          <Title
            order={5}
            onClick={() => {
              navigate('/perguntas-frequentes');
              setIsOpen(!isOpen);
            }}
          >
            PERGUNTAS FREQUENTES
          </Title>
          <Divider />
          <Title
            order={5}
            onClick={() => {
              navigate('/auth');
              setIsOpen(!isOpen);
            }}
          >
            ENTRAR / REGISTAR
          </Title>
          <Divider />
        </Stack>
      </Drawer>
    </>
  );
};
