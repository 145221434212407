import { useLocation } from 'react-router';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import { useGlobalStore } from '@/store/global';

import { MyAccount } from './MyAccount';

export const ProtectedRoutes = () => {
  const { user } = useGlobalStore();
  const location = useLocation();

  return user?.id ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" replace state={{ from: location }} />
  );
};

export const MyAccountRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<MyAccount />} path="/" />
    </Routes>
  );
};
