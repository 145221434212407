import create from 'zustand';
import { persist } from 'zustand/middleware';

type GlobalStore = {
  user?: User;
  setUser: (userData: User) => void;
  reset: () => void;
};

export const useGlobalStore = create(
  persist<GlobalStore>(
    (set) => ({
      user: undefined,
      setUser: (userData: User) => set({ user: userData }),
      reset: () => set({ user: undefined }),
    }),
    {
      name: '_u',
    }
  )
);
