import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { axiosInstance } from '@/config/axiosClient';

import { GetProductsResponse, Product } from './api';
const urlProducts = 'index.php?route=api/product/products';
const urlLatest = 'index.php?route=api/product/latest';
const urlOnSale = 'index.php?route=api/product/promotions';
const urlBestSellers = 'index.php?route=api/product/bestsellers';
const urlProductsSuggestion = 'index.php?route=api/product/productsSuggestion';
const urlProductDetail = 'index.php?route=api/product/productDetail';
const urlProductCategories = 'index.php?route=api/product/productCategories';
const urlProductImages = 'index.php?route=api/product/productImages';
const urlProductAttributes = 'index.php?route=api/product/productAttributes';

type HookParams = {
  perPage: number;
  page: number;
  categoryId: string;
  searchName: string;
};

export type ProductUI = {
  url: string;
  price: number;
  name: string;
  promotionPrice?: number;
  dateEnd?: Date;
  dateStart?: Date;
  categoryId?: string;
  productId: string;
  weight: number;
  unit: string;
  promotionValue?: number;
  promotionPricePerKg?: number;
  pricePerKg: number;
  minimum: number;
  description: string;
};

export type Category = {
  name: string;
  categoryId: number;
};

const getProductsMapped = (products: Product[] = []): ProductUI[] => {
  return (products || []).map(
    ({
      thumb,
      price,
      name,
      promotionPrice,
      dateEnd,
      dateStart,
      category_id,
      product_id,
      weight,
      unit,
      promotionValue,
      promotionPricePerKg,
      pricePerKg,
      minimum,
      description,
    }) => ({
      url: thumb,
      price,
      name,
      promotionPrice,
      dateEnd: dateEnd ? new Date(dateEnd) : dateEnd,
      dateStart: dateStart ? new Date(dateStart) : dateStart,
      categoryId: category_id,
      productId: product_id,
      weight,
      unit: unit || '',
      promotionValue,
      promotionPricePerKg,
      pricePerKg,
      minimum,
      description,
    })
  );
};

export const useGetProducts = ({
  perPage = 10,
  page = 1,
  categoryId = '59',
  searchName = '',
}: HookParams) => {
  const { data, isLoading } = useQuery<GetProductsResponse>(
    ['allProducts', [perPage, page, categoryId, searchName]],
    async () => {
      const { data } = await axiosInstance.get<GetProductsResponse>(
        urlProducts,
        {
          params: {
            page,
            perPage,
            search: searchName,
            categoryId,
          },
        }
      );
      return data;
    },
    {
      staleTime: 5000 * 60,
    }
  );

  const products = useMemo(
    () => getProductsMapped(data?.result?.products),
    [data]
  );
  return {
    products,
    total: data?.total,
    page: data?.page,
    isLoading,
    productsByCategory: groupBy(products, 'categoryId'),
  };
};

type LatestProductsParams = {
  perPage?: number;
};

export const useGetLatestProducts = ({
  perPage,
}: LatestProductsParams = {}) => {
  const { data, isLoading } = useQuery<Product[]>(
    ['productLatest'],
    async () => {
      const { data } = await axiosInstance.get(urlLatest, {
        params: { perPage },
      });
      //Request URL: http://localhost:3000/frutada5a/index.php?route=api/product/latest?perPage=4

      return data;
    }
  );

  const productsLatest = useMemo(() => getProductsMapped(data), [data]);

  return { productsLatest, isLoading };
};

export const useGetProductsOnSale = ({ perPage }: { perPage?: number }) => {
  const { data, isLoading } = useQuery<Product[]>(
    ['productsOnSale'],
    async () => {
      const { data } = await axiosInstance.get(urlOnSale, {
        params: { perPage },
      });
      return data;
    }
  );

  const productsOnSale = useMemo(() => getProductsMapped(data), [data]);

  return { productsOnSale, isLoading };
};

export const useGetBestSellersProducts = ({
  perPage,
}: {
  perPage?: number;
}) => {
  const { data, isLoading } = useQuery<Product[]>(
    ['productsBestSellers'],
    async () => {
      const { data } = await axiosInstance.get(urlBestSellers, {
        params: { perPage },
      });
      return data;
    }
  );

  const productsBestSellers = useMemo(() => getProductsMapped(data), [data]);

  return { productsBestSellers, isLoading };
};

export const useGetProductsSuggestion = () => {
  const { data, isLoading } = useQuery<Product[]>(
    ['productsSuggestion'],
    async () => {
      const { data } = await axiosInstance.get(urlProductsSuggestion);
      return data;
    }
  );

  const productsSuggestion = useMemo(() => getProductsMapped(data), [data]);

  return { productsSuggestion, isLoading };
};

export const useGetProductDetail = ({
  productName,
}: {
  productName: string;
}) => {
  const { data, isLoading } = useQuery<Product>(
    ['productDetail', productName],
    async () => {
      const { data } = await axiosInstance.get(urlProductDetail, {
        params: { name: productName },
      });
      return data;
    }
  );

  const productMapped = useMemo(
    () => data && getProductsMapped([data as Product]),
    [data]
  );

  const { product_id } = data || ({} as Product);

  const { data: categories, isLoading: isLoadingCategories } = useQuery<
    Category[]
  >(
    ['urlProductCategories', product_id],
    async () => {
      const { data } = await axiosInstance.get(urlProductCategories, {
        params: { product_id: product_id },
      });
      return data;
    },
    { enabled: Boolean(product_id) }
  );

  const { data: images, isLoading: isLoadingImages } = useQuery<string[]>(
    ['urlProductImages', product_id],
    async () => {
      const { data } = await axiosInstance.get(urlProductImages, {
        params: { product_id: product_id },
      });
      return data;
    },
    { enabled: Boolean(product_id) }
  );

  const { data: attributes, isLoading: isLoadingAttributes } = useQuery<
    {
      attribute_name: string;
      attribute_value: string;
    }[]
  >(
    ['urlProductAttributes', product_id],
    async () => {
      const { data } = await axiosInstance.get(urlProductAttributes, {
        params: { product_id: product_id },
      });
      return data;
    },
    { enabled: Boolean(product_id) }
  );

  return {
    productDetail: productMapped?.at(0),
    categories,
    images,
    attributes,
    isLoading:
      isLoading ||
      isLoadingCategories ||
      isLoadingImages ||
      isLoadingAttributes,
  };
};
