import {
  Anchor,
  Container,
  Divider,
  Footer as FooterMantine,
  Grid,
  Group,
  Image,
  List,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

import logo from '@/assets/logoInverted.png';

import styles from './Footer.module.scss';

const FULL_YEAR = new Date().getFullYear();

export const Footer = (): JSX.Element => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  return (
    <FooterMantine
      height={'auto'}
      p="xl"
      style={{ color: theme.white, backgroundColor: theme.colors.dark[9] }}
    >
      <Container style={{ height: 'inherit' }}>
        <Grid columns={18} align="center" style={{ height: 'inherit' }}>
          <Grid.Col md={6} lg={3}>
            <Stack justify="center">
              <Image
                height={120}
                width={120}
                src={logo}
                alt="Fruta da 5ª logotipo"
                withPlaceholder
                placeholder={<Text align="center">Logo</Text>}
              />
              <Text>Somos a ponte entre o produtor e a sua casa</Text>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <Stack className={styles.footerCol}>
              <Title order={3}>Fruta da 5ª</Title>
              <Divider />
              <List style={{ color: 'white' }} listStyleType={'none'}>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/home#casa"
                  >
                    Na sua casa
                  </Anchor>
                </List.Item>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/home#empresa"
                  >
                    Na sua empresa
                  </Anchor>
                </List.Item>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/home#revenda"
                  >
                    Para revenda
                  </Anchor>
                </List.Item>
              </List>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <Stack className={styles.footerCol}>
              <Title order={3}>Dias especiais</Title>
              <Divider />
              <List style={{ color: 'white' }} listStyleType={'none'}>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/home#eventos-especiais"
                  >
                    Fruta para eventos
                  </Anchor>
                </List.Item>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/home#presentes"
                  >
                    Ofereça um presente especial
                  </Anchor>
                </List.Item>
              </List>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <Stack className={styles.footerCol}>
              <Title order={3}>Minha conta</Title>
              <Divider />
              <List style={{ color: 'white' }} listStyleType={'none'}>
                <List.Item style={{ cursor: 'pointer' }}>
                  <Anchor
                    style={{ color: 'white' }}
                    component={Link}
                    to="/minha-conta"
                  >
                    Minha conta
                  </Anchor>
                </List.Item>
              </List>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <Stack className={styles.footerCol}>
              <Title order={3}>Contactos</Title>
              <Divider />
              <List style={{ color: 'white' }} listStyleType={'none'}>
                <List.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/contactos')}
                >
                  Contactos
                </List.Item>
              </List>
            </Stack>
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <Stack className={styles.footerCol}>
              <Title order={3}>Distinções</Title>
              <Divider />
              <Anchor href="https://scoring.pt/empresas/frutada5a/">
                <Image
                  maw={100}
                  src={'https://frutada5a.pt/image/catalog/2025/Selo.png'}
                  alt="em casa"
                  withPlaceholder
                  placeholder={<Text align="center">em casa</Text>}
                />
              </Anchor>
            </Stack>
          </Grid.Col>
        </Grid>
        <Divider mt={'xl'} />
        <Group py="xl" position="center">
          <Anchor
            style={{ color: theme.colors.gray[0] }}
            size="xs"
            href="https://www.livroreclamacoes.pt/"
          >
            Livro de reclamações
          </Anchor>
          <Divider
            orientation="vertical"
            style={{ color: theme.colors.gray[0], height: 'inherit' }}
          />
          <Anchor
            style={{ color: theme.colors.gray[0] }}
            size="xs"
            href="http://www.centrodearbitragemdecoimbra.com/"
          >
            Centro de Arbitragem de Conflitos de Consumo
          </Anchor>
          <Divider
            orientation="vertical"
            style={{ color: theme.colors.gray[0], height: 'inherit' }}
          />
          <Anchor
            style={{ color: theme.colors.gray[0] }}
            size="xs"
            component={Link}
            to="/termos-e-condicoes"
          >
            Termos e condições
          </Anchor>
        </Group>
        <Group position="center">
          <Text size="xs">
            © {FULL_YEAR} Frutada5a Unip, Lda. Todos os direitos reservados
          </Text>
        </Group>
      </Container>
    </FooterMantine>
  );
};
