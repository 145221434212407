import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/config/axiosClient';

import { UpdateAccessDetailsResponse } from './api';

const url = 'index.php?route=account/edit/editAccess';

type UpdateAccessDetailsParams = {
  password: string;
  confirm: string;
};

type UpdateAccessDetailsResult = {
  success: string;
};

type ErrorUpdateAccessDetails = {
  password?: string;
  confirm?: string;
};

const useUpdateAccessDetails = () => {
  const { mutate, isLoading, data } = useMutation<
    UpdateAccessDetailsResult,
    AxiosError<ErrorUpdateAccessDetails>,
    UpdateAccessDetailsParams,
    unknown
  >(
    async ({
      password,
      confirm,
    }: UpdateAccessDetailsParams): Promise<UpdateAccessDetailsResult> => {
      const { data } = await axiosInstance.post<UpdateAccessDetailsResponse>(
        url,
        {
          password,
          confirm,
        }
      );
      return {
        success: data.success,
      };
    }
  );

  return { updateAccessDetails: mutate, isSaving: isLoading, data };
};

export default useUpdateAccessDetails;
