import { Route, Routes } from 'react-router-dom';

import { Team } from './Team';

export const TeamRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Team />} path="/" />
    </Routes>
  );
};
