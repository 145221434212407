import { Tabs } from '@mantine/core';

import { AccessDetails, CommonDetails } from './components';
import { InvoiceDetails } from './components/InvoiceDetails';

enum TabValue {
  COMMON = 'common',
  ACCESS = 'access',
  INVOICE_DATA = 'invoiceData',
}

export const AccountDetails = () => {
  return (
    <Tabs color="dark" variant="default" defaultValue="common">
      <Tabs.List>
        <Tabs.Tab value={TabValue.COMMON}>Dados gerais</Tabs.Tab>
        <Tabs.Tab value={TabValue.ACCESS}>Dados de acesso</Tabs.Tab>
        <Tabs.Tab value={TabValue.INVOICE_DATA}>Dados de faturação</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value={TabValue.COMMON} pl="xl">
        <CommonDetails />
      </Tabs.Panel>

      <Tabs.Panel value={TabValue.ACCESS} pl="xl">
        <AccessDetails />
      </Tabs.Panel>

      <Tabs.Panel value={TabValue.INVOICE_DATA} pl="xl">
        <InvoiceDetails />
      </Tabs.Panel>
    </Tabs>
  );
};
