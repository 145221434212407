import { Accordion, Stack, Title } from '@mantine/core';

import { Categories } from '@/api/useGetCategories';

import { useFilterStore } from '../../filterStore/filterStore';

type Props = {
  categoryId: string;
  categories: Categories;
};

export const CategoryFilterItem = ({ categories, categoryId }: Props) => {
  const { setSelectedCategory } = useFilterStore();
  const { subcategories, name } = categories[categoryId];
  const transition = {
    transition: 'filter 0.5s',
    '&:hover': {
      filter: 'invert(0.5)',
    },
  };

  const handleClickCategoryName = (id: string) => () => setSelectedCategory(id);

  return (
    <Accordion.Item value={categoryId}>
      <Accordion.Control chevron={subcategories.length > 0 ? null : <></>}>
        <Title
          sx={() => ({ ...transition, cursor: 'pointer' })}
          key={categoryId}
          order={5}
          px="md"
        >
          {name}
        </Title>
      </Accordion.Control>
      {subcategories.length > 0 && (
        <Accordion.Panel>
          <Stack spacing="md">
            {subcategories.map(({ id, name }) => (
              <Title
                onClick={handleClickCategoryName(id)}
                key={id}
                order={6}
                pl="xl"
                sx={() => ({
                  ...transition,
                  cursor: 'pointer',
                })}
              >
                {name}
              </Title>
            ))}
          </Stack>
        </Accordion.Panel>
      )}
    </Accordion.Item>
  );
};
