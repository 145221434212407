import { Grid } from '@mantine/core';
import { ReactNode } from 'react';

import { ProductUI } from '@/api/useGetProducts';
import { SlideProducts } from '@/features/suggestions';

type Props = {
  children: ReactNode;
  products: ProductUI[];
};
export const StepContent = ({ children, products }: Props) => {
  return (
    <Grid columns={12}>
      <Grid.Col md={12} lg={7}>
        {children}
      </Grid.Col>
      <Grid.Col md={1} lg={1}></Grid.Col>

      <Grid.Col md={12} lg={4}>
        {products?.length > 0 && (
          <SlideProducts title="As nossas sugestões" products={products} />
        )}
      </Grid.Col>
    </Grid>
  );
};
