import { Container, Grid, Group, Stack, Title } from '@mantine/core';

import { TopMenu } from '@/components/TopMenu';
import { useCartStore } from '@/store/cart';

import { CartSummary } from '../components/CartSummary';
import { CartTable } from '../components/CartTable';
import { EmptyCart } from '../components/EmptyCart';

export const Cart = () => {
  const { cart } = useCartStore();
  const { totalAmount } = cart;

  return (
    <>
      <TopMenu />
      <Container size="xl" py="100px">
        <Stack>
          <Group position="center">
            <Title align="center">Carrinho de compras</Title>
          </Group>
          {cart.totalProducts === 0 ? (
            <EmptyCart />
          ) : (
            <Grid py={20}>
              <Grid.Col md={8} xs={12}>
                <CartTable products={cart.products}></CartTable>
              </Grid.Col>
              <Grid.Col md={4} xs={12}>
                <CartSummary totalAmount={totalAmount} />
              </Grid.Col>
            </Grid>
          )}
        </Stack>
      </Container>
    </>
  );
};
