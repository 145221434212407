import { Anchor, Group, MediaQuery, useMantineTheme } from '@mantine/core';

type Props = {
  text: string;
  iconName: string;
  href: string;
};

export const ContactIcon = ({ text, iconName, href }: Props) => {
  const theme = useMantineTheme();
  return (
    <>
      <Group spacing={3}>
        <Anchor color="dark" size="xs" href={href}>
          <i className={iconName} style={{ fontSize: theme.fontSizes.md }} />
        </Anchor>
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Anchor color="dark" size="xs" href={href}>
            {text}
          </Anchor>
        </MediaQuery>
      </Group>
    </>
  );
};
