import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';

import { CustomerModel } from './api';

const url = 'index.php?route=api/customer/customer';

type UserInfo = {
  isLoading: boolean;
  userInfo?: User;
  error: unknown;
};

type UserInfoResponse = {
  user: CustomerModel;
  error: [x: string];
};

const useUserInfo = (): UserInfo => {
  const { data, isLoading, error } = useQuery<UserInfoResponse>(
    ['useUserInfo'],
    async () => {
      const { data } = await axiosInstance.get<UserInfoResponse>(url);
      return data;
    }
  );

  if (!data?.user || isLoading) return { isLoading, error };

  const userInfo: User = {
    id: data.user.customer_id,
    firstName: data?.user.firstname,
    lastName: data?.user.lastname,
    telephone: data?.user.telephone,
    addressId: data?.user.address_id,
  };

  return { userInfo, isLoading, error };
};
export default useUserInfo;
