import { Route, Routes } from 'react-router-dom';

import { Store } from './Store';

export const StoreRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Store />} path="/" />
    </Routes>
  );
};
