import { AppShell, Center, Loader, MantineProvider, Text } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';

ReactGA.initialize(TRACKING_ID);

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { AppRoutes } from '@/routes';

import './assets/fontello/css/fontello.css';
import { CookieModal } from './components/CookieModal/CookieModal';
import { ScrollToTop } from './components/ScrollToTop';
import { TRACKING_ID } from './utils/constants';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const MainLoader = () => {
  return (
    <Center style={{ height: '100vh' }}>
      <Loader color="dark" />
    </Center>
  );
};

export const App = (): JSX.Element => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const showCookieDialog = () => setIsDialogOpened(true);

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider position="top-right" autoClose={3000}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MantineProvider withCSSVariables>
          <Suspense fallback={<MainLoader />}>
            <Router>
              <ScrollToTop />
              <AppShell
                fixed={false}
                styles={{
                  main: { padding: 0, maxWidth: '100%' },
                }}
                header={<Header />}
                footer={<Footer />}
              >
                <AppRoutes />
                <CookieConsent
                  extraCookieOptions={{ domain: 'frutada5a.pt' }}
                  buttonText="Concordo"
                  style={{ backgroundColor: 'rgb(16, 17, 19)' }}
                  buttonStyle={{ color: 'white', backgroundColor: '#40c057' }}
                >
                  <Text>
                    O nosso website utiliza cookies para melhorar e personalizar
                    a sua experiência de navegação. Ao continuar a navegar está
                    a consentir a utilização de cookies.{' '}
                    <span
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      role="button"
                      tabIndex={-1}
                      onKeyDown={() => {}}
                      onClick={showCookieDialog}
                    >
                      Saiba mais
                    </span>
                  </Text>
                </CookieConsent>
                <CookieModal
                  isOpened={isDialogOpened}
                  onClose={() => setIsDialogOpened(false)}
                ></CookieModal>
              </AppShell>
            </Router>
          </Suspense>
        </MantineProvider>
      </NotificationsProvider>
    </QueryClientProvider>
  );
};
