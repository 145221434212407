import {
  Card,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  url: string;
  name: string;
  promotionValue?: number;
};
export const ImageContainer = ({ url, name, promotionValue }: Props) => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  return (
    <Card shadow={'sm'} withBorder style={{ position: 'relative' }}>
      <Image
        style={{ width: breakpoint ? '330px' : 'unset' }}
        src={url}
        alt={name}
      />
      <Paper
        shadow={'xl'}
        radius="sm"
        px={5}
        py={0}
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          color: 'white',
          backgroundColor: 'black',
        }}
      >
        {promotionValue ? (
          <Stack spacing={0} align="center">
            <Group mt={-5} spacing={5}>
              <Text p="0" size="xs">
                + de
              </Text>
              <Text
                color="green"
                weight={'bold'}
                p="0"
                size={breakpoint ? 24 : 20}
              >
                {promotionValue}%
              </Text>
            </Group>
            <Text mt={-10} size="xs">
              de desconto
            </Text>
          </Stack>
        ) : null}
      </Paper>
    </Card>
  );
};
