import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/config/axiosClient';

import { DeleteAddressResponse } from './api';

const url = 'index.php?route=account/address/deleteAddress';

type DeleteAddressParams = {
  addressId: string;
};

type ErrorDeleteAddress = {
  [x: string]: string;
};

type DeleteAddressResult = {
  success?: string;
};

const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation<
    DeleteAddressResult,
    AxiosError<ErrorDeleteAddress>,
    DeleteAddressParams,
    unknown
  >(
    async ({
      addressId,
    }: DeleteAddressParams): Promise<DeleteAddressResult> => {
      const { data } = await axiosInstance.post<DeleteAddressResponse>(url, {
        addressId,
      });
      return {
        success: data.success,
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['useAddresses']);
      },
    }
  );

  return { deleteAddress: mutate, isSaving: isLoading, data };
};

export default useDeleteAddress;
