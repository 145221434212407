import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Container,
  Group,
  Select,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useUpdateInvoiceDetails from '@/api/useUpdateInvoiceDetails';
import useUserInvoiceDetails from '@/api/useUserInvoiceDetails';
import { REQUIRED_FIELD } from '@/utils/constants';

type FormData = {
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  other: string;
  city: string;
  zipCode1: string;
  zipCode2: string;
  countryId: string;
  nif: string;
};

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(32, 'Máximo 32 caracteres')
      .required(REQUIRED_FIELD),
    lastName: yup
      .string()
      .max(32, 'Máximo 32 caracteres')
      .required(REQUIRED_FIELD),
    company: yup.string().nullable(),
    street: yup
      .string()
      .max(128, 'Máximo 128 caracteres')
      .required(REQUIRED_FIELD),
    other: yup.string().nullable(),
    city: yup
      .string()
      .max(128, 'Máximo 128 caracteres')
      .required(REQUIRED_FIELD),
    zipCode1: yup
      .string()
      .min(4, 'Tem que conter 4 dígitos')
      .max(4, 'Tem que conter 4 dígitos')
      .required(REQUIRED_FIELD),
    zipCode2: yup
      .string()
      .min(3, 'Tem que conter 3 dígitos')
      .max(3, 'Tem que conter 3 dígitos')
      .required(REQUIRED_FIELD),
    countryId: yup.number().required(REQUIRED_FIELD),
    nif: yup
      .string()
      .min(9, 'Número inválido')
      .max(9, 'Número inválido')
      .matches(/\d{9}$/, 'Número inválido.')
      .required(REQUIRED_FIELD),
  })
  .required();

export const InvoiceDetails = () => {
  const theme = useMantineTheme();
  const { userInvoiceInfo, isLoading } = useUserInvoiceDetails();
  const { updateAccessDetails, isSaving } = useUpdateInvoiceDetails();

  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const inputWidth = breakpoint ? '50%' : '100%';

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const isFormValid = Object.keys(errors).length === 0 && isDirty;

  useEffect(() => {
    if (isLoading && !userInvoiceInfo) return;
    reset({ ...userInvoiceInfo, countryId: '171' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = () => {
    const fields = watch();
    updateAccessDetails(
      { ...fields, addressId: userInvoiceInfo?.addressId },
      {
        onSuccess: () => {
          showNotification({
            title: 'Sucesso',
            message: 'Alterações efetuadas com sucesso.',
            color: 'green',
          });
        },
        onError: ({ response }) => {
          setError('firstName', { message: response?.data.firstname });
          setError('lastName', { message: response?.data.lastname });
          setError('city', { message: response?.data.city });
          setError('street', { message: response?.data.address_1 });
          showNotification({
            title: 'Erro',
            message: 'Ocorreu um erro a guardar as alterações',
            color: 'red',
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container py="xl">
        <Stack spacing="md">
          <TextInput
            style={{ width: inputWidth }}
            placeholder="nr. contribuinte"
            label="Número de contribuinte:"
            required
            error={errors.nif?.message}
            {...register('nif')}
          />
          <TextInput
            style={{ width: inputWidth }}
            error={errors.firstName?.message}
            placeholder="nome"
            label="Nome:"
            required
            {...register('firstName')}
          />
          <TextInput
            style={{ width: inputWidth }}
            error={errors.lastName?.message}
            placeholder="apelidos"
            label="Apelidos:"
            required
            {...register('lastName')}
          />

          <TextInput
            style={{ width: inputWidth }}
            placeholder="nome da empresa"
            label="Empresa:"
            type="text"
            {...register('company')}
          />

          <TextInput
            style={{ width: inputWidth }}
            placeholder="morada"
            label="Rua, número:"
            type="text"
            required
            {...register('street')}
          />
          <TextInput
            style={{ width: inputWidth }}
            placeholder="outros dados"
            label="Outros dados:"
            type="text"
            {...register('other')}
          />

          <TextInput
            style={{ width: inputWidth }}
            placeholder="cidade"
            label="Cidade:"
            type="text"
            required
            {...register('city')}
          />
          <Group>
            <TextInput
              style={{ width: breakpoint ? '24%' : '33%' }}
              placeholder="ex: 3000"
              label="Código postal:"
              required
              {...register('zipCode1')}
            />
            <TextInput
              style={{ width: breakpoint ? '24%' : '33%' }}
              placeholder="ex: 123"
              label=" "
              {...register('zipCode2')}
            />
          </Group>
          <Select
            style={{ width: inputWidth }}
            placeholder="país"
            label="País:"
            required
            value={'171'}
            data={[{ value: '171', label: 'Portugal' }]}
          />

          <Stack style={{ width: inputWidth }} align="end">
            <Button
              loading={isSaving}
              disabled={!isFormValid}
              type="submit"
              color="dark"
            >
              Guardar
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
