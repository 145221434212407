import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Container,
  Group,
  Modal,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useAddresses from '@/api/useAddresses';
import useUpdateAddress from '@/api/useUpdateAddress';
import { REQUIRED_FIELD } from '@/utils/constants';

type FormData = {
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  other: string;
  city: string;
  zipCode1: string;
  zipCode2: string;
  countryId: string;
  nif: string;
};

const schema = yup
  .object({
    firstName: yup
      .string()
      .max(32, 'Máximo 32 caracteres')
      .required(REQUIRED_FIELD),
    lastName: yup
      .string()
      .max(32, 'Máximo 32 caracteres')
      .required(REQUIRED_FIELD),
    company: yup.string().nullable(),
    street: yup
      .string()
      .max(128, 'Máximo 128 caracteres')
      .required(REQUIRED_FIELD),
    other: yup.string().nullable(),
    city: yup
      .string()
      .max(128, 'Máximo 128 caracteres')
      .required(REQUIRED_FIELD),
    zipCode1: yup
      .string()
      .min(4, 'Tem que conter 4 dígitos')
      .max(4, 'Tem que conter 4 dígitos')
      .required(REQUIRED_FIELD),
    zipCode2: yup
      .string()
      .min(3, 'Tem que conter 3 dígitos')
      .max(3, 'Tem que conter 3 dígitos')
      .required(REQUIRED_FIELD),
    // countryId: yup.number().required(REQUIRED_FIELD),
  })
  .required();

type Props = {
  isOpened: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  addressId?: string;
};

export const AddEditAddressModal = ({
  isOpened,
  onConfirm,
  onClose,
  title,
  addressId = '',
}: Props) => {
  const { updateAddress, isSaving } = useUpdateAddress();
  const { addressesById } = useAddresses();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const isFormValid = Object.keys(errors).length === 0;

  useEffect(() => {
    if (isEmpty(addressesById[addressId])) {
      reset({
        firstName: '',
        lastName: '',
        city: '',
        countryId: '',
        company: '',
        street: '',
        other: '',
        zipCode1: '',
        zipCode2: '',
      });
      return;
    }

    const {
      firstName,
      lastName,
      city,
      postcode,
      company,
      address1: street,
      address2: other,
    } = addressesById[addressId];
    const [zipCode1, zipCode2] = postcode.split('-');
    const formAddress = {
      firstName,
      lastName,
      city,
      company,
      street,
      other,
      zipCode1,
      zipCode2,
    };

    reset({ ...formAddress });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  const onSubmit = () => {
    const fields = watch();
    updateAddress(
      { ...fields, addressId },
      {
        onSuccess: () => {
          onConfirm();
          showNotification({
            title: 'Sucesso',
            message: 'Alterações efetuadas com sucesso.',
            color: 'green',
          });
        },
        onError: ({ response }) => {
          setError('firstName', { message: response?.data.firstname });
          setError('lastName', { message: response?.data.lastname });
          setError('city', { message: response?.data.city });
          setError('street', { message: response?.data.address_1 });
          showNotification({
            title: 'Erro',
            message: 'Ocorreu um erro a guardar as alterações',
            color: 'red',
          });
        },
      }
    );
  };

  return (
    <Modal size="lg" centered opened={isOpened} onClose={onClose} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Stack spacing="md">
            <Group grow>
              <TextInput
                error={errors.firstName?.message}
                placeholder="nome"
                required
                label="Nome:"
                {...register('firstName')}
              />
              <TextInput
                error={errors.lastName?.message}
                placeholder="apelidos"
                required
                label="Apelidos:"
                {...register('lastName')}
              />
            </Group>

            <TextInput
              placeholder="nome da empresa"
              label="Empresa:"
              type="text"
              {...register('company')}
            />
            <Group grow>
              <TextInput
                error={errors.street?.message}
                placeholder="Rua, número..."
                label="Morada"
                type="text"
                required
                {...register('street')}
              />
              <TextInput
                placeholder="morada(linha 2, opcional)"
                label=" "
                type="text"
                {...register('other')}
              />
            </Group>
            <TextInput
              required
              error={errors.city?.message}
              placeholder="cidade"
              label="Cidade:"
              type="text"
              {...register('city')}
            />
            <Group grow>
              <TextInput
                required
                error={errors.zipCode1?.message}
                placeholder="ex: 3000"
                label="Código postal:"
                {...register('zipCode1')}
              />
              <TextInput
                error={errors.zipCode2?.message}
                placeholder="ex: 123"
                label=" "
                {...register('zipCode2')}
              />
            </Group>
            <Select
              placeholder="país"
              label="País:"
              value={'171'}
              data={[{ value: '171', label: 'Portugal' }]}
            />

            <Stack align="end">
              <Group position="center">
                <Button color="gray" onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  loading={isSaving}
                  disabled={!isFormValid}
                  type="submit"
                  color="dark"
                >
                  Guardar
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Container>
      </form>
    </Modal>
  );
};
