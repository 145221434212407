export const TeamPics = [
  'https://frutada5a.pt/image/catalog/Atletas/bruno_f.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/flavio150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/joana_f.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/jose_f.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/sandra_g.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/hugo150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/simao150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/laudelina150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/pedro150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/luis150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/sofia150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/sergio150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/joao1501502.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/ana150150.jpg',
  'https://frutada5a.pt/image/catalog/Atletas/salvador1501502.jpg',
];

export const Sponsors = [
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/frutorra_frutos_secos.png',
    link: '',
  },
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/SpoTherapy_foot.png',
    link: 'https://www.spotherapy.pt/',
  },
  {
    imgUrl: 'https://frutada5a.pt/image/catalog/Patrocinadores/cyclespa.png',
    link: 'https://www.facebook.com/cyclespace.pt/',
  },
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/Hosttugatech1.png',
    link: 'https://host.tugatech.com.pt/',
  },
  {
    imgUrl: 'https://frutada5a.pt/image/catalog/Patrocinadores/kabazuk3.png',
    link: 'https://www.facebook.com/KabazuK/',
  },
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/peixariamarazul.png',
    link: 'https://www.facebook.com/peixemarazul.peixemarazul',
  },
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/PauloRedondo.png',
    link: 'https://www.facebook.com/pauloredondoseguro',
  },
  {
    imgUrl: 'https://frutada5a.pt/image/catalog/Patrocinadores/meusuper2.png',
    link: '',
  },
  {
    imgUrl:
      'https://frutada5a.pt/image/catalog/Patrocinadores/Logo_Dsmei_1.png',
    link: 'http://www.dsmei.pt/',
  },
  {
    imgUrl: 'https://frutada5a.pt/image/catalog/Patrocinadores/laserprint.png',
    link: 'http://www.laserprint.pt/',
  },
];
