import { Alert, Table, Text, Textarea } from '@mantine/core';

import useAddresses from '@/api/useAddresses';
import useDeliveryPrices from '@/api/useDeliveryPrices';
import { useCheckoutStore } from '@/store/checkout';
import {
  CUSTOM_SCHEDULE_ID,
  DAYS_OF_WEEK,
  workingDays,
} from '@/utils/constants';

import styles from './delivery.module.scss';
import { RouteInfo } from './RouteInfo';
import { Row } from './Row';

export const Delivery = () => {
  const { addressId, comments, setComments } = useCheckoutStore();
  const { addressesById } = useAddresses();
  const postCode = addressesById[addressId]?.postcode;
  const { deliveryPrices, isLoading, routeName } = useDeliveryPrices({
    postCode,
  });

  if (isLoading || !deliveryPrices) return <div>loading</div>;
  const schedules = Object.keys(deliveryPrices).filter(
    (scheduleId) => parseInt(scheduleId) !== CUSTOM_SCHEDULE_ID
  );
  return (
    <>
      <Alert style={{ textAlign: 'center' }} color="dark" variant="filled">
        <Text component="span" weight={'bold'}>
          Recomendamos que faça a sua encomenda até às 15h do dia anterior.
        </Text>
      </Alert>
      <Table mt="md" captionSide="top" withColumnBorders horizontalSpacing="xs">
        <RouteInfo routeName={routeName} addressId={addressId} />
        <thead>
          <tr>
            <th className={styles.textCenter}>Horário/Dia</th>
            {workingDays.map((day) => (
              <th className={styles.textCenter} key={day}>
                {DAYS_OF_WEEK[day]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {schedules.map((deliveryPriceKey) => {
            return (
              <Row
                key={deliveryPriceKey}
                priceInfo={deliveryPrices[parseInt(deliveryPriceKey)]}
              />
            );
          })}
          <Row priceCustom={deliveryPrices[CUSTOM_SCHEDULE_ID]} />
        </tbody>
      </Table>
      <Textarea
        value={comments}
        onChange={(event) => setComments(event.currentTarget.value)}
        mt={40}
        label="Comentários e/ou informações que considere importante."
      />
    </>
  );
};
