import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/config/axiosClient';

import { UpdateCommonDetailsResponse } from './api';

const url = 'index.php?route=account/edit/editCommonDetails';

type UpdateCommonDetailsParams = {
  firstName: string;
  lastName: string;
  telephone: string;
};

type UpdateCommonDetailsResult = {
  success: string;
};

type ErrorUpdateCommonDetails = {
  firstname?: string;
  lastname?: string;
  telephone?: string;
};

const useUpdateCommonDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation<
    UpdateCommonDetailsResult,
    AxiosError<ErrorUpdateCommonDetails>,
    UpdateCommonDetailsParams,
    unknown
  >(
    async ({
      firstName,
      lastName,
      telephone,
    }: UpdateCommonDetailsParams): Promise<UpdateCommonDetailsResult> => {
      const { data } = await axiosInstance.post<UpdateCommonDetailsResponse>(
        url,
        {
          firstName,
          lastName,
          telephone,
        }
      );
      return {
        success: data.success,
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['useUserInfo']);
      },
    }
  );

  return { updateCommonDetails: mutate, isSaving: isLoading, data };
};

export default useUpdateCommonDetails;
