import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';

import { CustomerInvoiceDetailsResponse } from './api';

const url = 'index.php?route=api/customer/customerInvoiceDetails';

export type UserInvoice = {
  addressId: number;
  street: string;
  other: string;
  city: string;
  company: string;
  countryId: string;
  nif: string;
  firstName: string;
  lastName: string;
  zipCode1: string;
  zipCode2: string;
};

type UserInvoiceDetails = {
  isLoading: boolean;
  userInvoiceInfo?: UserInvoice;
  error: unknown;
};

const useUserInvoiceDetails = (): UserInvoiceDetails => {
  const { data, isLoading, error } = useQuery<CustomerInvoiceDetailsResponse>(
    ['useUserInvoiceDetails'],
    async () => {
      const { data } = await axiosInstance.get<CustomerInvoiceDetailsResponse>(
        url
      );
      return data;
    }
  );

  if (!data?.customerInvoice || isLoading) return { isLoading, error };
  const [zipCode1, zipCode2] = data.customerInvoice.postcode?.split('-') || [];
  const userInvoiceInfo = {
    addressId: parseInt(data.customerInvoice.address_id),
    street: data.customerInvoice.address_1,
    other: data.customerInvoice.address_2,
    city: data.customerInvoice.city,
    company: data.customerInvoice.company,
    countryId: data.customerInvoice.country_id,
    nif: data.customerInvoice.custom_field[1],
    firstName: data.customerInvoice.firstname,
    lastName: data.customerInvoice.lastname,
    zipCode1,
    zipCode2,
  };

  return { userInvoiceInfo, isLoading, error };
};
export default useUserInvoiceDetails;
