import { Route, Routes } from 'react-router-dom';

import { FAQ } from './FAQ';

export const FAQRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<FAQ />} path="/" />
    </Routes>
  );
};
