import { Accordion, Title } from '@mantine/core';
import { useState } from 'react';

import useGetCategories from '@/api/useGetCategories';

import { useFilterStore } from '../../filterStore/filterStore';

import { CategoryFilterItem } from './';

export const CategoriesFilter = () => {
  const { categories } = useGetCategories();
  const { selectedCategory, setSelectedCategory } = useFilterStore();
  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(
    selectedCategory
  );
  const categoriesKeys = Object.keys(categories);

  const handleChange = (accordion: string | null) => {
    setSelectedAccordion(accordion);
    if (!accordion) return;
    setSelectedCategory(accordion);
  };

  return (
    <>
      <Title order={3}>Categorias</Title>
      <Accordion
        value={selectedAccordion}
        onChange={handleChange}
        variant="filled"
        defaultValue={categoriesKeys[0]}
      >
        {categoriesKeys.map((categoryId) => (
          <CategoryFilterItem
            key={categoryId}
            categories={categories}
            categoryId={categoryId}
          />
        ))}
      </Accordion>
    </>
  );
};
