import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { axiosInstance } from '@/config/axiosClient';

import { CategoriesResponse } from './api';
const url = 'index.php?route=product/category/categories';

export type Categories = {
  [x: string]: {
    name: string;
    subcategories: {
      id: string;
      name: string;
    }[];
  };
};

const useGetCategories = () => {
  const { data, isLoading } = useQuery<CategoriesResponse>(
    ['categories'],
    async () => {
      const { data } = await axiosInstance.get<CategoriesResponse>(url);
      return data;
    }
  );

  const categories = useMemo(() => {
    return (data?.categories || [])
      .sort((a, b) => parseInt(a.parent_id) - parseInt(b.parent_id))
      .reduce<Categories>((agg, val) => {
        if (parseInt(val.parent_id) === 0) {
          return {
            ...agg,
            [val.category_id]: {
              name: val.name,
              subcategories: [],
            },
          };
        }
        return {
          ...agg,
          [val.parent_id]: {
            ...agg[val.parent_id],
            subcategories: [
              ...agg[val.parent_id].subcategories,
              { id: val.category_id, name: val.name },
            ],
          },
        };
      }, {} as Categories);
  }, [data]);

  return { categories, isLoading };
};

export default useGetCategories;
