import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { axiosInstance } from '@/config/axiosClient';

import { AddressApi } from './api';

const url = 'index.php?route=account/address/addresses';

export type Address = {
  addressId: string;
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  country: string;
};

type UserAddresses = {
  addresses: Address[];
  isLoading: boolean;
  error: unknown;
  addressesById: { [x: string]: Address };
};

const useAddresses = (): UserAddresses => {
  const { data, isLoading, error } = useQuery<AddressApi[]>(
    ['useAddresses'],
    async () => {
      const { data } = await axiosInstance.get<AddressApi[]>(url);
      return data;
    }
  );

  const addresses: Address[] = useMemo(() => {
    if (isLoading) return [];
    return (data || []).map((addr) => ({
      addressId: addr.address_id,
      firstName: addr.firstname,
      lastName: addr.lastname,
      company: addr.company,
      address1: addr.address_1,
      address2: addr.address_2,
      city: addr.city,
      postcode: addr.postcode,
      country: addr.country,
    }));
  }, [data, isLoading]);

  return {
    addresses,
    addressesById: keyBy(addresses, 'addressId'),
    isLoading,
    error,
  };
};
export default useAddresses;
