/* eslint-disable react/no-unescaped-entities */
import { Modal, Stack, Text, Title } from '@mantine/core';

export const CookieModal = ({
  isOpened,
  onClose,
}: {
  isOpened: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      size={500}
      centered
      opened={isOpened}
      onClose={onClose}
      title={<Title order={1}>Política de Cookies</Title>}
    >
      <Stack spacing="xl">
        <Text>
          Utilizamos cookies que permitem melhorar o desempenho e a experiência
          do utilizador conforme descrito abaixo.
        </Text>
        <Title order={3}>Conhecer os cookies</Title>
        <Text>
          Os cookies são pequenos ficheiros de texto com informação relevante
          que o seu dispositivo de acesso (computador, telemóvel/smartphone ou
          tablet) carrega, através do navegador de internet (browser), quando um
          site é visitado pelo utilizador.
          <br />A colocação de cookies não só ajudará o site a reconhecer o
          dispositivo do utilizador na próxima vez que este o visita mas também,
          com frequência, será imprescindível para o funcionamento do mesmo. Os
          cookies usados não recolhem informações pessoais que permitam
          identificar o utilizador. Guardam informações genéricas,
          designadamente a forma ou local/país de acesso dos utilizadores e o
          modo como usam os sites, entre outros. Os cookies retêm apenas
          informação relacionada com as suas preferências.
          <br />O utilizador pode, a qualquer momento e através do seu navegador
          de internet (browser), decidir ser notificado sobre a recepção de
          cookies, bem como bloquear a respectiva entrada no seu sistema.
          Salienta-se, no entanto, que a recusa de uso de cookies no site pode
          resultar na impossibilidade de acesso a algumas das suas áreas e não
          permitir toda a experiência de navegação.
        </Text>
        <Title order={3}>Funcionalidade dos cookies</Title>
        <Text>
          Em geral, os cookies são usados para melhorar a experiência de
          navegação do utilizador, aumentando a rapidez e eficiência de
          resposta. Eliminam a necessidade de introduzir repetidamente as mesmas
          informações e ajudam a determinar que as mensagens publicitárias que o
          utilizador vê são relevantes e se ajustam aos seus interesses, ou
          seja, a utilidade, interesse e o número de utilizações dos sites.
          <br /> Cada cookie utilizado tem uma função e uma data de validade.
        </Text>
        <Title order={3}>Bloquear o uso de cookies</Title>
        <Text>
          O utilizador pode desactivar parte ou a totalidade dos cookies a
          qualquer momento. Deve, para tal, seguir as instruções apresentadas
          abaixo. Ao desactivar os cookies o site pode não funcionar
          correctamente.
        </Text>
        <Title order={3}>Gerir o uso de cookies</Title>
        <Text>
          Utilizamos cookies que permitem melhorar o desempenho e a experiência
          do utilizador.Todos os navegadores de internet (browsers) permitem ao
          utilizador aceitar, recusar ou apagar cookies, através da gestão das
          definições no respectivo navegador. Caso desactive os cookies pode
          impedir que alguns serviços da internet funcionem correctamente,
          afectando, parcial ou totalmente, a navegação no site.
          <br /> Como pode activar e desactivar os cookies no navegador de
          internet (browser).
          <br />
          <br />
          <Text weight={'bold'}>Google Chrome</Text>
          <br />
          <Text>
            O utilizador tem controlo total sobre as permissões de cookies no
            Google Chrome. Por pré-definição, são permitidos todos os cookies,
            mas pode ajustar esta definição. Se os cookies tiverem sido
            definidos ou bloqueados na página que estiver a visualizar, é
            apresentado um ícone no final da barra de endereço.
            <br />
            <br />
            Clique no menu do Chrome na barra de ferramentas Seleccione
            "Definições" Clique em "Mostrar definições avançadas" Na secção
            "Privacidade", clique no botão "Definições de conteúdo" Seleccione a
            opção "Bloquear definição dos dados por parte dos sites" Pode
            alterar as seguintes definições de cookies na secção "Cookies":
            <br /> - Eliminar cookies
            <br />- Bloquear cookies por pré-definição - Permitir cookies por
            pré-definição
            <br />- Manter cookies e dados do website por pré-definição até sair
            do navegador
            <br />- Criar excepções para cookies de websites ou domínios
            específicos
            <br />
            <br />
            <b>Nota:</b> Existem vários níveis de activação e desactivação de
            cookies no Google Chrome. Para mais informações sobre definições de
            cookies no Google Chrome, consulte a página
            http://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647&topic=14666&ctx=topic
          </Text>
          <br />
          <br />
          <Text weight={'bold'}>Microsoft Internet Explorer</Text>
          <Text>
            Para desactivar todos os cookies no Internet Explorer: <br />
            <br />
            Clique no menu "Ferramentas" e escolha a opção "Opções de Internet"
            Seleccione o separador "Privacidade" Mova a barra para o topo, onde
            surgirá "Bloquear todos os cookies"
            <br />
            <br />
            <b>Nota:</b> Existem vários níveis de activação e desactivação de
            cookies no Internet Explorer. Para mais informações sobre definições
            de cookies Internet Explorer, consulte a página da Microsoft
            http://windows.microsoft.com/pt-PT/windows-vista/Block-or-allow-cookies
          </Text>
          <br />
          <br />
          <Text weight={'bold'}>Mozilla Firefox</Text>
          <Text>
            Para desactivar todos os cookies no Mozilla Firefox: <br />
            Clique no menu "Ferramentas" Seleccione "Opções"Clique no ícone
            "Privacidade", que encontra no painel superior Na secção "Cookies"
            desactive a opção "Aceitar cookies dos sites" Clique em "OK" para
            guardar as alterações e fechar.
            <br />
            <br />
            <b>Nota:</b> Existem vários níveis de activação e desactivação de
            cookies no Mozilla Firefox. Para mais informações sobre definições
            de cookies no Mozilla Firefox, consulte a página
            http://support.mozilla.org/pt-PT/kb/Activar%20e%20Desactivar%20cookies?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies
          </Text>
          <br />
          <br />
          <Text weight={'bold'}>Safari</Text>
          <Text>
            Para desactivar todos os cookies no Safari: <br />
            Clique em "Editar" Seleccione "Preferências" No painel superior,
            seleccione o ícone "Privacidade" Na secção "Aceitar cookies",
            seleccione "Nunca"
            <br />
            <br />
            <b>Nota</b>: Existem vários níveis de activação e desactivação de
            cookies no Safari. Para mais informações sobre definições de cookies
            no Safari, consulte a página
            http://support.apple.com/kb/HT1677?viewlocale=pt_PT
          </Text>
          <br />
          <br />
          <Text weight={'bold'}>
            Todos os outros navegadores de internet (browsers)
          </Text>
          <Text>
            Por favor, procure no menu "ajuda" do navegador (browser) ou
            contacte o fornecedor do navegador (browser).
          </Text>
        </Text>
      </Stack>
    </Modal>
  );
};
