import {
  Button,
  Container,
  Group,
  Stack,
  Text,
  Title,
  // useMantineTheme,
} from '@mantine/core';
// import { useMediaQuery } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';

import {
  ProductUI,
  useGetLatestProducts,
  useGetProductsSuggestion,
} from '@/api/useGetProducts';
import { ProductCard } from '@/components/ProductCard';
import { TopMenu } from '@/components/TopMenu';
import { useCartStore } from '@/store/cart';

const sliderSettings: Settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
};

type SlideProductsProps = {
  products: ProductUI[];
  title: string;
};
export const SlideProducts = ({ products, title }: SlideProductsProps) => {
  return (
    <Stack>
      <Title order={3}>{title}</Title>
      <Slider {...sliderSettings}>
        {products.map(
          ({
            url,
            price,
            name,
            promotionPrice,
            pricePerKg,
            promotionPricePerKg,
            unit,
            productId,
            promotionValue,
            dateEnd,
            weight,
            minimum,
          }) => (
            <ProductCard
              productId={productId}
              key={productId}
              url={url}
              price={price}
              name={name}
              promotionPrice={promotionPrice}
              unit={unit}
              pricePerKg={pricePerKg}
              promotionPricePerKg={promotionPricePerKg}
              promotionValue={promotionValue}
              promotionDateEnd={dateEnd}
              weight={weight}
              minimum={minimum}
            />
          )
        )}
      </Slider>
    </Stack>
  );
};

export const Suggestions = () => {
  const { productsSuggestion, isLoading } = useGetProductsSuggestion();
  const {
    cart: { products },
  } = useCartStore();
  const { productsLatest, isLoading: isLoadingLatest } = useGetLatestProducts({
    perPage: 10,
  });
  if (isLoading || isLoadingLatest) return null;
  const productsLatestWithoutCart = productsLatest.filter(
    ({ productId }) =>
      !products.find((p) => p.productId === parseInt(productId))
  );
  return (
    <>
      <TopMenu />
      <Container size="xl" py="100px">
        <Stack>
          <Group position="apart">
            <Stack>
              <Title>Recomendações</Title>
              <Text>
                Conheça as nossas sugestões para completar a sua encomenda.
              </Text>
            </Stack>
            <Button component={Link} to="/checkout" color="dark">
              Continuar
            </Button>
          </Group>
          <SlideProducts
            title="E os habituais...?"
            products={productsSuggestion}
          />
          <SlideProducts
            title="E estas novidades?"
            products={productsLatestWithoutCart}
          />
        </Stack>
      </Container>
    </>
  );
};
