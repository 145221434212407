import {
  Container,
  createStyles,
  Group,
  Stack,
  Stepper,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useMemo, useState } from 'react';

import useCreateOrder from '@/api/useCreateOrder';
import {
  useGetProductsOnSale,
  useGetProductsSuggestion,
} from '@/api/useGetProducts';
import { TopMenu } from '@/components/TopMenu';
import { useCartStore } from '@/store/cart';
import { useCheckoutStore } from '@/store/checkout';
import { useGlobalStore } from '@/store/global';

import { ActionButtons } from './components/ActionButtons/ActionButtons';
import { Confirmation } from './components/Confirmation';
import { Delivery } from './components/Delivery';
import { Invoice } from './components/Invoice';
import { Payment } from './components/Payment';
import { ShippingAddress } from './components/ShippingAddress';
import { StepContent } from './components/StepContent';
import { ThankYou } from './components/ThankYou/ThankYou';

const NUMBER_STEPS = 5;
const useStyles = createStyles((theme) => ({
  content: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      overflowX: 'auto',
    },
  },
}));
export const Checkout = () => {
  const { classes } = useStyles();
  const { productsSuggestion: prodSuggest } = useGetProductsSuggestion();
  const { productsOnSale } = useGetProductsOnSale({ perPage: 10 });

  const productsSuggestion = [...prodSuggest, ...productsOnSale];

  const {
    comments,
    addressId: selectedAddressId,
    idPrice: selectedIdPrice,
    paymentMethod,
    readTC,
    hasInvoiceData,
  } = useCheckoutStore();
  const { user } = useGlobalStore();
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const [active, setActive] = useState(0);
  const { createOrder } = useCreateOrder();
  const { voucher } = useCartStore();

  const nextStep = async () => {
    if (active === 4) {
      createOrder({
        customerId: user?.id,
        comment: comments,
        shippingAddressId: parseInt(selectedAddressId),
        paymentMethod,
        priceId: selectedIdPrice,
        voucherCode: voucher.code,
        voucherAmount: voucher.amount,
      });
    }

    setActive((current) => (current < NUMBER_STEPS ? current + 1 : current));
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const handleStepClick = (stepIndex: number) => {
    if (active === 5) return;
    setActive(stepIndex);
  };

  const isNextButtonDisabled = useMemo(() => {
    if (active === 0) return !hasInvoiceData;
    if (active === 1) return !selectedAddressId.length;
    if (active === 2) return selectedIdPrice === -1;
    if (active === 3) return !paymentMethod.length || !readTC;
  }, [
    active,
    selectedAddressId,
    selectedIdPrice,
    paymentMethod,
    readTC,
    hasInvoiceData,
  ]);

  return (
    <>
      <TopMenu />
      <Container size="xl" py="100px">
        <Stack>
          <Group position="center">
            <Title align="center">Finalização</Title>
          </Group>

          <Stack>
            <Stepper
              allowNextStepsSelect={false}
              classNames={classes}
              color={'dark'}
              breakpoint={0}
              contentPadding={50}
              active={active}
              onStepClick={handleStepClick}
            >
              <Stepper.Step label={breakpoint ? ' Facturação' : ''}>
                <StepContent products={productsSuggestion}>
                  <Invoice />
                  <ActionButtons
                    isNextDisabled={isNextButtonDisabled}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    active={active}
                  />
                </StepContent>
              </Stepper.Step>
              <Stepper.Step label={breakpoint ? 'Endereço de entrega' : ''}>
                <StepContent products={productsSuggestion}>
                  <ShippingAddress />
                  <ActionButtons
                    isNextDisabled={isNextButtonDisabled}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    active={active}
                  />
                </StepContent>
              </Stepper.Step>
              <Stepper.Step label={breakpoint ? 'Data de entrega' : ''}>
                <StepContent products={productsSuggestion}>
                  <Delivery />
                  <ActionButtons
                    isNextDisabled={isNextButtonDisabled}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    active={active}
                  />
                </StepContent>
              </Stepper.Step>

              <Stepper.Step label={breakpoint ? 'Forma de pagamento' : ''}>
                <StepContent products={productsSuggestion}>
                  <Payment />
                  <ActionButtons
                    isNextDisabled={isNextButtonDisabled}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    active={active}
                  />
                </StepContent>
              </Stepper.Step>
              <Stepper.Step label={breakpoint ? 'Confirmação' : ''}>
                <StepContent products={productsSuggestion}>
                  <Confirmation />
                  <ActionButtons
                    isNextDisabled={isNextButtonDisabled}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    active={active}
                  />
                </StepContent>
              </Stepper.Step>
              <Stepper.Completed>
                <ThankYou />
              </Stepper.Completed>
            </Stepper>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
