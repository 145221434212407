import { Anchor, Checkbox, Radio, Stack, Text, Textarea } from '@mantine/core';
import { Link } from 'react-router-dom';

import { useCheckoutStore } from '@/store/checkout';

export const Payment = () => {
  const {
    comments,
    setComments,
    paymentMethod,
    setPaymentMethod,
    readTC,
    setReadTC,
  } = useCheckoutStore();

  return (
    <Stack>
      <Radio.Group
        value={paymentMethod}
        onChange={setPaymentMethod}
        spacing={'xs'}
        size="md"
        name="favoriteFramework"
        orientation="vertical"
        label="Selecione a forma de pagamento de sua preferência para esta compra."
        description="A factura será enviada por e-mail no início do dia da entrega. Todos os
        pagamentos devem ser realizados logo após a recepcção da factura."
        withAsterisk
      >
        <Radio color="dark" mt={'xl'} value="cod_1" label="MBWay" />
        <Radio
          color="dark"
          value="cod"
          label="NETBANCO ou Transferência Bancária"
        />
        <Radio
          color="dark"
          value="bank_transfer"
          label="MB / TPA (Recomendamos o uso QR-CODE | MB WAY)"
        />
        <Radio color="dark" value="cod_2" label="Cartão Refeição" />
        <Radio color="dark" value="cod_3" label="Cartão Refeição Euroticket" />
      </Radio.Group>
      <Textarea
        value={comments}
        onChange={(event) => setComments(event.currentTarget.value)}
        mt={40}
        label="Comentários e/ou informações que considere importante."
      />
      <Checkbox
        checked={readTC}
        onChange={() => setReadTC(!readTC)}
        color="dark"
        required
        label={
          <>
            Li e aceito os{' '}
            <Anchor
              component={Link}
              color={'dark'}
              size="sm"
              to="/termos-e-condicoes"
              target="_blank"
            >
              Termos e Condições{' '}
              <Text component="span" color="red">
                *
              </Text>
            </Anchor>
          </>
        }
      ></Checkbox>
    </Stack>
  );
};
