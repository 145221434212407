import { Header as HeaderMantine } from '@mantine/core';

import { TopNav } from './TopNav';

export const Header = (): JSX.Element => {
  return (
    <HeaderMantine height="auto">
      <TopNav />
    </HeaderMantine>
  );
};
