import {
  Card,
  Container,
  Group,
  Image,
  List,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { TopMenu } from '@/components/TopMenu';

export const AboutUs = () => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  return (
    <>
      <TopMenu />
      <Container size="md" p="xl" py="lg">
        <Stack
          justify="center"
          align="center"
          px={breakpoint ? 30 : 0}
          mt={breakpoint ? 0 : '50px'}
        >
          <Group>
            <Title>Quem somos</Title>
            <Stack>
              <Text size="sm">
                Fundada em <b>2014</b> na freguesia de Pereira, conselho de
                Montemor-o-Velho, a <b>FRUTA DA 5ª</b> nasceu com a missão de
                disponibilizar produtos hortofrutícolas de qualidade superior,
                garantindo a satisfação contínua dos nossos clientes.
                <br />
                <br />
              </Text>
              <Group noWrap={breakpoint} position="center">
                <Image
                  style={{ flexGrow: 0 }}
                  fit="contain"
                  src="https://frutada5a.pt/image/catalog/2025/quem_somos1.jpg"
                  maw={'200px'}
                  alt="valores"
                />
                <Text size="sm">
                  <b>
                    FRUTA DA 5ª - Somos a ponte entre o produtor e a sua casa.
                  </b>
                  <br />
                  <br />
                  Acreditamos que a frescura e a qualidade são essenciais para
                  uma alimentação saudável. Por isso, dedicamo-nos à seleção
                  rigorosa e à entrega das melhores frutas e legumes – sempre
                  frescos, saborosos e da mais alta qualidade.
                  <br />
                  <br />
                  Trabalhamos lado a lado com produtores nacionais,
                  privilegiando frutas da época e apoiando pequenos produtores.
                  Visitamos regularmente as suas produções, para garantir os
                  melhores produtos e oferecer um atendimento mais próximo e
                  personalizado.
                </Text>
              </Group>
              <Text size="sm">
                <br />
                <br />
                Na <b>Fruta da 5ª</b>, não vendemos apenas fruta. Queremos
                partilhar felicidade, cor e sabor, despertando todos os seus
                sentidos. Para nós, cada peça de fruta é uma experiência que
                combina qualidade, emoção e o prazer de uma alimentação
                saudável.
                <br />
                <br />
              </Text>
              <Group position="center">
                <Image
                  style={{ flexGrow: 0 }}
                  fit="contain"
                  src="https://frutada5a.pt/image/catalog/2025/quem_somos2.jpg"
                  maw={breakpoint ? '70%' : '100%'}
                  alt="valores"
                />
              </Group>
              <br />

              <Text size="sm">
                Em<b> 2014</b>, fomos pioneiros em Portugal ao transportar
                cabazes em viaturas especialmente preparadas e climatizadas. Em
                <b> 2015</b>, lançamos a{' '}
                <b>
                  primeira loja online em Portugal dedicada exclusivamente a
                  frutas e legumes
                </b>
                , permitindo que os clientes personalizassem os seus cabazes de
                forma simples e prática.
                <br />
                <br />
              </Text>
              <Group noWrap={breakpoint} position="center">
                <Text size="sm">
                  Desde <b>2018</b>, marcamos presença no{' '}
                  <b>Mercado Abastecedor da Região de Coimbra</b>, ampliando os
                  nossos serviços e atingindo um público cada vez mais amplo. Em
                  <b> 2021</b>, recebemos a primeira Certificação{' '}
                  <b>&quot;TOP 5% MELHORES PME DE PORTUGAL&quot;</b> pela
                  SCORING, um reconhecimento da nossa qualidade e compromisso.
                  <br />
                  <br />
                  <br />
                  Em <b>2023</b>, fomos escolhidos pela marca <b>FRUTORRA</b>{' '}
                  para dar continuidade à sua representação no{' '}
                  <b>Mercado Abastecedor da Região de Coimbra</b> e concluímos o
                  registo da nossa marca no
                  <b> Instituto Nacional da Propriedade Industrial</b>.
                  <br />
                  <br />
                  <br />
                  No início de <b>2025</b>, apresentámos a{' '}
                  <b>nossa nova loja online</b>, com funcionalidades aprimoradas
                  para uma experiência de compra ainda mais prática e
                  conveniente.
                </Text>
                <Image
                  style={{ flexGrow: 0 }}
                  fit="contain"
                  src="https://frutada5a.pt/image/catalog/2025/quem_somos3.jpg"
                  maw={'250px'}
                  alt="valores"
                />
              </Group>
              <br />
              <Text size="sm">
                Trabalhamos diariamente para oferecer uma experiência de
                confiança, rapidez e excelência.
                <br />
                <br />
                Seja para a sua casa, o seu negócio ou para presentear alguém
                especial, a Fruta da 5ª está aqui para trazer saúde e sabor à
                sua rotina.
                <br />
                <br />
                <b>FRUTA DA 5ª - Facilidade e satisfação em sua casa.</b>
              </Text>
              <Group mt="xl" grow={breakpoint}>
                <Card
                  p="xl"
                  style={{ height: breakpoint ? '660px' : 'unset' }}
                  shadow="sm"
                  radius="md"
                  withBorder
                >
                  <Card.Section>
                    <Image
                      src="https://frutada5a.pt/image/catalog/2025/quem_somos_b4.jpg"
                      height={160}
                      alt="valores"
                    />
                  </Card.Section>
                  <Group position="center" mt="md" mb="xl">
                    <Title order={3}>Valores</Title>
                  </Group>
                  <List spacing="xl" size="sm" center icon={<></>}>
                    <List.Item>
                      <b>Gostamos do que fazemos: </b>A paixão pelo nosso
                      trabalho reflete-se em cada detalhe.
                    </List.Item>
                    <List.Item>
                      <b>Compromisso com a qualidade: </b>Nunca vendemos um
                      produto que nós mesmos não compraríamos.
                    </List.Item>
                    <List.Item>
                      <b>O prazer de comprar e vender: </b>O nosso gosto em
                      comprar é sempre igual ao gosto que temos em vender.
                    </List.Item>
                    <List.Item>
                      <b>Negócios reais e honestos: </b>Elaboramos propostas
                      sempre realistas e transparentes.
                    </List.Item>
                    <List.Item>
                      <b>Cuidados no transporte:</b>Todos os produtos são
                      transportados em viaturas especialmente preparadas e
                      climatizadas, garantindo que chegam até si na sua melhor
                      forma.
                    </List.Item>
                  </List>
                </Card>
                <Card
                  p="xl"
                  style={{ height: breakpoint ? '660px' : 'unset' }}
                  shadow="sm"
                  radius="md"
                  withBorder
                >
                  <Card.Section>
                    <Image
                      src="https://frutada5a.pt/image/catalog/2025/quem_somos_b2.jpg"
                      height={160}
                      alt="objectivo"
                    />
                  </Card.Section>
                  <Group position="center" mt="md" mb="xl">
                    <Title order={3}>Objectivos</Title>
                  </Group>
                  <List spacing="xl" size="sm" center icon={<></>}>
                    <List.Item>
                      <b>Qualidade e satisfação: </b>Oferecer aos nossos
                      clientes produtos hortofrutícolas de elevada e
                      diferenciada qualidade e assegurar continuamente a sua
                      satisfação.
                    </List.Item>
                    <List.Item>
                      <b>Acompanhamento personalizado: </b>Aconselhar e
                      acompanhar de perto todos os clientes.
                    </List.Item>
                    <List.Item>
                      <b>Relação direta com produtores: </b>Manter uma relação
                      próxima com os nossos produtores, acompanhando as
                      produções para levar até si os melhores produtos.
                    </List.Item>
                    <List.Item>
                      <b>Promoção nacional: </b>Valorizar e promover os produtos
                      e as produções nacionais.
                    </List.Item>
                  </List>
                </Card>

                <Card
                  p="xl"
                  style={{ height: breakpoint ? '660px' : 'unset' }}
                  shadow="sm"
                  radius="md"
                  withBorder
                >
                  <Card.Section>
                    <Image
                      src="https://frutada5a.pt/image/catalog/2025/quem_somos_b1.jpg"
                      height={160}
                      alt="Norway"
                    />
                  </Card.Section>
                  <Group
                    position="center"
                    mt="md"
                    mb="xl"
                    style={{ textAlign: 'center' }}
                  >
                    <Title order={3}>O que nos diferencia? </Title>
                  </Group>
                  <List spacing="xl" size="sm" center icon={<></>}>
                    <List.Item>
                      <b>Produtos frescos: </b>Trabalhamos com fornecedores de
                      confiança para garantir que os nossos clientes recebem
                      sempre os melhores produtos, frescos e de alta qualidade.{' '}
                    </List.Item>
                    <List.Item>
                      <b>Sustentabilidade:</b> Priorizamos práticas
                      responsáveis, como a redução da utilização de sacos
                      plásticos e a reutilização de embalagens, com o objetivo
                      de diminuir a nossa pegada ecológica.{' '}
                    </List.Item>
                    <List.Item>
                      <b>Atendimento personalizado:</b> Valorizamos cada cliente
                      e fazemos tudo ao nosso alcance para atender às suas
                      necessidades com dedicação, cuidado e eficiência.{' '}
                    </List.Item>
                  </List>
                </Card>
              </Group>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </>
  );
};
