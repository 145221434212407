import create from 'zustand';

const PER_PAGE = 1000;

type FilterStore = {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  perPage: number;
  setPerPage: (newPerPage?: number) => void;
  page: number;
  setPage: (newPage: number) => void;
  searchName: string;
  setSearchName: (newName: string) => void;
};

const DEFAULT_CATEGORY = '59'; // é a categoria "Todos"

export const useFilterStore = create<FilterStore>((set) => ({
  selectedCategory: DEFAULT_CATEGORY,
  setSelectedCategory: (category: string) =>
    set({ selectedCategory: category, page: 1 }),
  page: 1,
  setPage: (newPage: number) => set({ page: newPage }),
  perPage: PER_PAGE,
  setPerPage: (newPerPage = PER_PAGE) => set({ perPage: newPerPage }),
  searchName: '',
  setSearchName: (newName: string) => set({ searchName: newName }),
}));
