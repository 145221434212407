import { Navigate, useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';
export const AppRoutes = () => {
  const commonRoutes = [
    { path: '*', element: <Navigate replace to="/home" /> },
  ];

  const element = useRoutes([...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
