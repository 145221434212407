import { useEffect } from 'react';

import { CouponApiResponse } from '@/api/api';
import useGetCoupon from '@/api/useGetCoupon';
import { useCartStore } from '@/store/cart';

const getVoucherAmount = (
  couponData?: CouponApiResponse['data'],
  cart?: Cart
) => {
  const couponValue = cart?.products.reduce((agg, val) => {
    if (!couponData?.product.includes(val.productId)) return agg;
    const discount = Number(couponData.discount);
    if (couponData.type === 'P') {
      agg += (val.totalValue * discount) / 100;
      return agg;
    } else if (couponData.type === 'F') {
      agg =
        Math.max(agg + val.totalValue, val.totalValue) >= discount
          ? discount
          : agg + val.totalValue;
      return agg;
    }
    return agg;
  }, 0);
  console.log('<<<couponValue>>>', couponValue);
  return couponValue || 0;
};

export const useVoucher = () => {
  const { setVoucher, voucher, cart } = useCartStore();
  const { getVoucher, data } = useGetCoupon();

  useEffect(() => {
    if (!data) return;
    if (data.error) {
      setVoucher({
        error: data.error,
        name: '',
        amount: 0,
        code: '',
        minSubTotal: 0,
      });
      return;
    }

    if ((cart.subTotal || 0) <= Number(data.data.total)) {
      setVoucher({
        ...voucher,
        amount: 0,
      });
      return;
    }

    setVoucher({
      amount: getVoucherAmount(data.data, cart),
      name: data.data.name,
      code: data.data.code,
      minSubTotal: Number(data.data.total) || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, data, setVoucher]);

  return {
    getVoucher,
  };
};
