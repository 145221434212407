import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Container,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useUpdateAccessDetails from '@/api/useUpdateAccessDetails';
import { REQUIRED_FIELD } from '@/utils/constants';

type FormData = {
  password: string;
  confirm: string;
};

const schema = yup
  .object({
    password: yup
      .string()
      .min(4, 'Insira pelo menos 4 caracteres.')
      .max(20, 'A senha tem que ser inferior a 20 caracteres.')
      .required(REQUIRED_FIELD),
    confirm: yup
      .string()
      .min(4, 'Insira pelo menos 4 caracteres.')
      .max(20, 'A senha tem que ser inferior a 20 caracteres.')
      .oneOf([yup.ref('password'), null], 'As senhas não são iguais')
      .required(REQUIRED_FIELD),
  })
  .required();

export const AccessDetails = () => {
  const theme = useMantineTheme();
  const { updateAccessDetails, isSaving } = useUpdateAccessDetails();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const inputWidth = breakpoint ? '50%' : '100%';

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setError,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
  });

  const { password, confirm } = watch();
  const isFormValid = Object.keys(errors).length === 0 && isDirty;

  const onSubmit = () => {
    updateAccessDetails(
      { password, confirm },
      {
        onSuccess: () => {
          reset();
          showNotification({
            title: 'Sucesso',
            message: 'Alterações efetuadas com sucesso.',
            color: 'green',
          });
        },
        onError: ({ response }) => {
          reset();
          setError('password', { message: response?.data.password });
          setError('confirm', { message: response?.data.confirm });

          showNotification({
            title: 'Erro',
            message: 'Ocorreu um erro a guardar as alterações',
            color: 'red',
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container py="xl">
        <Stack spacing="md">
          <TextInput
            error={errors.password?.message}
            style={{ width: inputWidth }}
            placeholder="nova senha"
            label="Senha:"
            required
            type="password"
            {...register('password')}
          />

          <TextInput
            error={errors.confirm?.message}
            style={{ width: inputWidth }}
            placeholder="confirmar a senha"
            label="Confirmar a senha:"
            type="password"
            required
            {...register('confirm')}
          />
          <Stack style={{ width: inputWidth }} align="end">
            <Button
              loading={isSaving}
              disabled={!isFormValid}
              type="submit"
              color="dark"
            >
              Guardar
            </Button>
          </Stack>
        </Stack>
      </Container>
    </form>
  );
};
