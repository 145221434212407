import { RouteObject } from 'react-router-dom';

import { AboutUsRoutes } from '@/features/about-us';
import { CartRoutes } from '@/features/cart';
import { Checkout } from '@/features/checkout';
import { ContactsRoutes } from '@/features/contacts';
import { FAQRoutes } from '@/features/faq';
import { MyAccountRoutes, ProtectedRoutes } from '@/features/my-account';
import { ProductRoutes } from '@/features/product/';
import { StoreRoutes } from '@/features/store';
import { Suggestions } from '@/features/suggestions';
import { TeamRoutes } from '@/features/team';
import { TermsAndConditionsRoutes } from '@/features/terms-conditions';
import { lazyImport } from '@/utils/lazyImport';

const { AuthRoutes } = lazyImport(
  () => import('@/features/auth'),
  'AuthRoutes'
);

const { PromotionsRoutes } = lazyImport(
  () => import('@/features/promotions'),
  'PromotionsRoutes'
);

const { LandingRoutes } = lazyImport(
  () => import('@/features/landing'),
  'LandingRoutes'
);

export const publicRoutes: RouteObject[] = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/home/*',
    element: <LandingRoutes />,
  },
  {
    path: '/promocoes/*',
    element: <PromotionsRoutes />,
  },
  {
    path: '/loja/*',
    element: <StoreRoutes />,
  },
  {
    path: '/carrinho/*',
    element: <CartRoutes />,
  },
  {
    path: '/checkout/*',
    element: <Checkout />,
  },
  {
    path: '/sugestoes/*',
    element: <Suggestions />,
  },
  {
    path: '/produto/*',
    element: <ProductRoutes />,
  },
  {
    path: '/contactos/*',
    element: <ContactsRoutes />,
  },
  {
    path: '/perguntas-frequentes/*',
    element: <FAQRoutes />,
  },
  {
    path: '/termos-e-condicoes/*',
    element: <TermsAndConditionsRoutes />,
  },
  {
    path: '/quem-somos/*',
    element: <AboutUsRoutes />,
  },
  {
    path: '/equipa/*',
    element: <TeamRoutes />,
  },
  {
    path: '/minha-conta/*',
    element: <ProtectedRoutes />,
    children: [{ path: '/minha-conta/*', element: <MyAccountRoutes /> }],
  },
];
