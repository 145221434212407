import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/config/axiosClient';

import { UpdateAddressesResponse } from './api';

const editUrl = 'index.php?route=account/address/editAddress';
const createUrl = 'index.php?route=account/address/addAddress';

type UpdateAddressParams = {
  addressId?: string;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  other: string;
  city: string;
  zipCode1: string;
  zipCode2: string;
  countryId: string;
};

type UpdateAddressResult = {
  success: string;
};

type ErrorUpdateAddress = {
  firstname?: string;
  lastname?: string;
  address_1?: string;
  city?: string;
  postcode?: string;
  country?: string;
};

const useUpdateAddress = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation<
    UpdateAddressResult,
    AxiosError<ErrorUpdateAddress>,
    UpdateAddressParams,
    unknown
  >(
    async ({
      addressId,
      firstName,
      lastName,
      company,
      street,
      other,
      city,
      zipCode1,
      zipCode2,
      countryId,
    }: UpdateAddressParams): Promise<UpdateAddressResult> => {
      const url = addressId ? editUrl : createUrl;

      const { data } = await axiosInstance.post<UpdateAddressesResponse>(url, {
        address1: street,
        address2: other,
        city,
        company,
        countryId,
        postcode: `${zipCode1}-${zipCode2}`,
        firstname: firstName,
        lastname: lastName,
        addressId,
      });
      return {
        success: data.success,
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['useAddresses']);
      },
    }
  );

  return { updateAddress: mutate, isSaving: isLoading, data };
};

export default useUpdateAddress;
