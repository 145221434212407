import { Button, Card, Group, Stack, Text } from '@mantine/core';

type Props = {
  onDeleteClick: (addressId: string) => void;
  onEditClick: (addressId: string) => void;
  address: {
    addressId: string;
    firstName: string;
    lastName: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    postcode: string;
    country: string;
  };
  canDelete: boolean;
};

export const AddressCard = ({
  canDelete,
  onDeleteClick,
  onEditClick,
  address: {
    addressId,
    firstName,
    lastName,
    company,
    address1,
    address2,
    city,
    postcode,
    country,
  },
}: Props) => {
  return (
    <Card
      style={{ width: '300px', height: '230px' }}
      key={addressId}
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
    >
      <Stack justify="space-between" style={{ height: '100%' }}>
        <Stack spacing="xs">
          <Text size="sm"> {`${firstName} ${lastName}`} </Text>
          {company && <Text size="xs"> {company}</Text>}
          <Text size="xs"> {`${address1} ${address2}`} </Text>
          <Text size="xs"> {`${postcode} ${city}`}</Text>
          <Text size="xs"> {country} </Text>
        </Stack>
        <Group position="right">
          {canDelete && (
            <Button
              onClick={() => onDeleteClick(addressId)}
              compact
              variant="subtle"
              size="xs"
              color="red"
            >
              APAGAR
            </Button>
          )}
          <Button
            onClick={() => onEditClick(addressId)}
            compact
            variant="subtle"
            size="xs"
            color="dark"
          >
            EDITAR
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};
