import {
  ActionIcon,
  Group,
  NumberInput,
  NumberInputHandlers,
} from '@mantine/core';
// import { debounce } from 'lodash';
import { useRef } from 'react';

type Props = {
  value: number;
  buttonSize: number;
  inputWidth: number;
  onChange?: (newValue: number) => void;
  minimum: number;
};

export const NumberInputWithButtons = ({
  value,
  buttonSize,
  inputWidth,
  onChange,
  minimum,
}: Props) => {
  const handlers = useRef<NumberInputHandlers>();

  const handleIncrement = () => {
    handlers?.current?.increment();
  };
  const handleDecrement = () => {
    value > minimum && handlers?.current?.decrement();
  };
  const handleOnChange = (newValue: number) => {
    if (onChange && newValue > 0 && newValue >= minimum) onChange(newValue);
  };
  return (
    <Group spacing={0}>
      <ActionIcon size={buttonSize} variant="default" onClick={handleDecrement}>
        -
      </ActionIcon>

      <NumberInput
        formatter={(value) =>
          Number(value) > minimum ? `${value}` : `${minimum}`
        }
        hideControls
        handlersRef={handlers}
        min={minimum}
        value={value}
        step={1}
        onChange={handleOnChange}
        styles={{
          input: { height: buttonSize, width: inputWidth, textAlign: 'center' },
        }}
      />

      <ActionIcon size={buttonSize} variant="default" onClick={handleIncrement}>
        +
      </ActionIcon>
    </Group>
  );
};
