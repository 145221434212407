import { Tooltip } from '@mantine/core';
import { useState } from 'react';

import { useCartStore } from '@/store/cart';
import { useCheckoutStore } from '@/store/checkout';

import styles from '../delivery.module.scss';

const PRICE_THRESHOLD = 5;

type Props = {
  idPrice: number;
  price: number;
  mergeCells?: boolean;
};
export const PriceCell = ({ idPrice, price, mergeCells }: Props) => {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const {
    idPrice: selectedIdPrice,
    setIdPrice,
    setPriceValue,
  } = useCheckoutStore();
  const {
    cart: { subTotal },
  } = useCartStore();

  const priceToLock = price - PRICE_THRESHOLD;

  const activeClass = selectedIdPrice === idPrice ? styles.active : '';
  const inactiveClass =
    price === 0 || (subTotal || 0) < priceToLock ? styles.inactive : '';

  const priceToShow = price === 0 ? '' : `${price}€`;

  const handleClick = () => {
    if (price === 0 || (subTotal || 0) < priceToLock) return;
    const newIdPrice = selectedIdPrice === idPrice ? -1 : idPrice;
    setIdPrice(newIdPrice);
    setPriceValue(price);
  };

  return (
    <td
      colSpan={mergeCells ? 6 : 0}
      role="presentation"
      onClick={handleClick}
      className={`${styles.cell} ${styles.textCenter} ${inactiveClass} ${activeClass}`}
    >
      {(subTotal || 0) < priceToLock ? (
        <Tooltip
          onMouseLeave={() => setIsTooltipOpened(false)}
          opened={isTooltipOpened}
          label={
            <span>
              O valor da sua encomenda é inferior ao valor mínimo. <br />
              Por favor, adicione mais produtos ao seu carrinho.
            </span>
          }
        >
          <span
            tabIndex={-1}
            onKeyDown={() => setIsTooltipOpened(true)}
            role="button"
            onMouseEnter={() => setIsTooltipOpened(true)}
            onClick={() => setIsTooltipOpened(true)}
          >
            {priceToShow}
          </span>
        </Tooltip>
      ) : (
        priceToShow
      )}
    </td>
  );
};
