import { Select } from '@mantine/core';
import { useMemo } from 'react';

import useGetCategories from '@/api/useGetCategories';

import { useFilterStore } from '../../filterStore/filterStore';

export const CategoriesDropdown = () => {
  const { categories } = useGetCategories();
  const { selectedCategory, setSelectedCategory } = useFilterStore();
  const dropdowmOptions = useMemo(() => {
    return Object.keys(categories)
      .map((categoryKey) => {
        const { name, subcategories } = categories[categoryKey];
        if (subcategories.length === 0)
          return [{ label: name, value: categoryKey, group: 'Geral' }];

        return [
          { label: `${name} - Todos`, value: categoryKey, group: name },
          ...subcategories.map((subCategory) => ({
            label: subCategory.name,
            value: subCategory.id,
            group: name,
          })),
        ];
      })
      .flat();
  }, [categories]);
  return (
    <Select
      onChange={(value) => setSelectedCategory(value as string)}
      size="sm"
      label="Categorias"
      style={{ width: '40%', flexGrow: 1 }}
      pr="xl"
      defaultValue={selectedCategory}
      data={dropdowmOptions}
    ></Select>
  );
};
