import {
  Alert,
  Button,
  Group,
  NumberInput,
  SegmentedControl,
  Stack,
  Transition,
} from '@mantine/core';
import { useMemo, useState } from 'react';

import useCart from '@/api/useCart';
import { ProductUI } from '@/api/useGetProducts';
import { useCartStore } from '@/store/cart';

type Props = {
  unit: ProductUI['unit'];
  minimum: ProductUI['minimum'];
  productId: ProductUI['productId'];
  weight: ProductUI['weight'];
};

export const AddProduct = ({ unit, minimum, productId, weight }: Props) => {
  const { getProductById } = useCartStore();

  const initialMinimum = useMemo(() => {
    return (getProductById(Number(productId))?.quantity || 1) >= minimum ||
      unit.toUpperCase() === 'KG'
      ? 1
      : minimum;
  }, [getProductById, minimum, productId, unit]);

  const [selectedUnit, setSelectedUnit] = useState(unit);
  const [valueToShow, setValueToShow] = useState(0);
  const [inputValue, setInputValue] = useState(initialMinimum);
  const [opened, setOpen] = useState(false);

  const { addToCart } = useCart();

  const handleAddClick = () => {
    const min =
      (getProductById(Number(productId))?.quantity || 1) >= minimum ||
      inputValue >= minimum
        ? inputValue
        : minimum;
    const nrByKg = Math.max(minimum, Math.ceil((1000 / weight) * inputValue));
    const finalValueToAdd = selectedUnit?.toUpperCase() === 'UN' ? min : nrByKg;

    setValueToShow(finalValueToAdd);
    setTimeout(() => setOpen(false), 1000);
    setOpen(true);
    addToCart({ productId, quantity: finalValueToAdd });
  };

  return (
    <Stack style={{ position: 'relative' }}>
      <Transition
        mounted={opened}
        transition="slide-up"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Alert
            radius="md"
            variant="light"
            color="green"
            style={{
              ...styles,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              transform: 'translateY(-100%)',
            }}
          >
            Adicionou {valueToShow} {valueToShow > 1 ? 'produtos' : 'produto'}{' '}
            ao carrinho
          </Alert>
        )}
      </Transition>
      <Group grow align={'start'}>
        <Stack spacing={0} style={{ maxWidth: '150px' }}>
          <NumberInput
            decimalSeparator="."
            min={1}
            precision={selectedUnit === 'UN' ? 0 : 2}
            step={selectedUnit === 'UN' ? 1 : 0.01}
            onChange={(value: number | undefined) => {
              setInputValue(value || 0);
            }}
            hideControls
            value={inputValue}
          />
          {unit.toLowerCase() !== 'un' ? (
            <SegmentedControl
              onChange={setSelectedUnit}
              value={selectedUnit?.toUpperCase()}
              color={'dark'}
              size="xs"
              data={['UN', 'KG']}
            />
          ) : null}
        </Stack>

        <Button
          style={{ maxWidth: '100px' }}
          color="green"
          onClick={handleAddClick}
        >
          Adicionar
        </Button>
      </Group>
    </Stack>
  );
};
