import { Button, Group, Stack, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';

import useAddresses from '@/api/useAddresses';
import useDeleteAddress from '@/api/useDeleteAddress';
import useUserInfo from '@/api/useUserInfo';
import { ConfirmationModal } from '@/components/ConfirmationModal';

import { AddEditAddressModal } from './components/AddEditAddressModal';
import { AddressCard } from './components/AddressCard';

export const AccountAddresses = () => {
  const { addresses } = useAddresses();
  const [addressToAct, setAddressToAct] = useState<string>('');
  const { userInfo } = useUserInfo();
  const { deleteAddress } = useDeleteAddress();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isAddEditModalOpened, setIsAddEditModalOpened] = useState(false);

  const handleOpenEditModal = (addressId: string) => {
    setAddressToAct(addressId);
    setIsAddEditModalOpened(true);
  };

  const handleOnConfirmEditModal = () => {
    setIsAddEditModalOpened(false);
    setAddressToAct('');
  };

  const handleOnConfirm = () => {
    deleteAddress(
      { addressId: addressToAct },
      {
        onSettled: () => {
          setIsModalOpened(false);
          setAddressToAct('');
        },
        onSuccess: () => {
          showNotification({
            title: 'Sucesso',
            message: 'Alterações efetuadas com sucesso.',
            color: 'green',
          });
        },
        onError: () => {
          showNotification({
            title: 'Erro',
            message: 'Ocorreu um erro a guardar as alterações',
            color: 'red',
          });
        },
      }
    );
  };
  const handleOnDeleteClick = (addressId: string) => {
    setAddressToAct(addressId);
    setIsModalOpened(true);
  };
  const handleModalClose = () => {
    setAddressToAct('');
    setIsModalOpened(false);
  };
  return (
    <>
      <AddEditAddressModal
        title={!addressToAct ? 'Adicionar novo endereço' : 'Editar endereço'}
        isOpened={isAddEditModalOpened}
        onClose={() => setIsAddEditModalOpened(false)}
        onConfirm={handleOnConfirmEditModal}
        addressId={addressToAct}
      />
      <ConfirmationModal
        title="Apagar endereço"
        onConfirm={handleOnConfirm}
        onClose={handleModalClose}
        isOpened={isModalOpened}
      >
        <Text size="sm">
          Tem a certeza que pretende apagar este endereço? Esta acção é
          irreversível.
        </Text>
      </ConfirmationModal>
      <Stack>
        <Group position="right">
          <Button onClick={() => handleOpenEditModal('')} color="dark">
            Adicionar novo endereço
          </Button>
        </Group>
        <Group spacing="md">
          {addresses.map((address) => {
            return (
              <AddressCard
                onEditClick={handleOpenEditModal}
                onDeleteClick={handleOnDeleteClick}
                key={address.addressId}
                address={address}
                canDelete={address.addressId !== userInfo?.addressId}
              />
            );
          })}
        </Group>
      </Stack>
    </>
  );
};
