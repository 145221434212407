import {
  Anchor,
  Container,
  Group,
  Image,
  Stack,
  Text,
  Title,
} from '@mantine/core';

import { TopMenu } from '@/components/TopMenu';

import { Sponsors, TeamPics } from '../utils/constants';

export const Team = () => {
  return (
    <>
      <TopMenu />
      <Container size="md" py="60px">
        <Stack>
          <Group>
            <Title>Equipa BTT, Trail & Caminhada</Title>
            <Stack>
              <Image
                maw={630}
                src={
                  'https://frutada5a.pt/image/catalog/Patrocinadores/capa%20facebook.jpg'
                }
                alt="equipa"
                withPlaceholder
                placeholder={<Text align="center">Equipa</Text>}
              />
              <Stack mt="xl">
                <Text>
                  O nome da <b>Equipa Fruta da 5ª</b> surge em 2016 com um grupo
                  de clientes e amigos, que vestiram a mesma camisola e
                  (re)começaram a participar em provas de{' '}
                  <b>BTT, Trail & Caminhada.</b>
                  <br />
                  <br />
                  Com a equipa pretendemos aproximar todos os clientes e
                  parceiros nas diversas actividades.
                  <br />
                  <br />
                  Queremos demonstrar a importância da actividade física e de
                  uma alimentação saudável para o bem-estar físico, mental e
                  social.
                  <br />
                  <br />
                  Pretendemos salientar os benefícios de qualquer actividade e
                  promover o desporto ao ar livre e a prática desportiva.
                </Text>
                <Title order={3}>Atletas</Title>
                <Group>
                  {TeamPics.map((url) => {
                    return (
                      <Image
                        key={url}
                        maw={150}
                        src={url}
                        alt="equipa"
                        withPlaceholder
                        placeholder={<Text align="center">Equipa</Text>}
                      />
                    );
                  })}
                </Group>
                <Group>
                  {Sponsors.map(({ imgUrl, link }) => {
                    if (link) {
                      return (
                        <Anchor href={link}>
                          <Image
                            maw={100}
                            src={imgUrl}
                            alt={link}
                            withPlaceholder
                            placeholder={<Text align="center">link</Text>}
                          />
                        </Anchor>
                      );
                    }
                    return (
                      <Image
                        maw={100}
                        key={link}
                        src={imgUrl}
                        alt={link}
                        withPlaceholder
                        placeholder={<Text align="center">link</Text>}
                      />
                    );
                  })}
                </Group>
                <Title mt="20px" order={3}>
                  Apoio institucional
                </Title>
                <Image
                  maw={150}
                  src={
                    'https://frutada5a.pt/image/catalog/Patrocinadores/CMMV-COR.png'
                  }
                  alt={'apoio'}
                  withPlaceholder
                  placeholder={<Text align="center">apoio institucional</Text>}
                />
              </Stack>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </>
  );
};
