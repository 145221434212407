import create from 'zustand';

type CheckoutStore = {
  addressId: string;
  setAddressId: (addressId: string) => void;
  idPrice: number;
  setIdPrice: (idPrice: number) => void;
  priceValue: number;
  setPriceValue: (priceValue: number) => void;
  comments: string;
  setComments: (newComment: string) => void;
  paymentMethod: string;
  setPaymentMethod: (paymentMethod: string) => void;
  readTC: boolean;
  setReadTC: (readTC: boolean) => void;
  hasInvoiceData: boolean;
  setHasInvoiceData: (hasInvoiceData: boolean) => void;
};

export const useCheckoutStore = create<CheckoutStore>((set) => ({
  addressId: '',
  setAddressId: (addressId) => set({ addressId }),
  idPrice: -1,
  setIdPrice: (idPrice) => set({ idPrice }),
  priceValue: -1,
  setPriceValue: (priceValue) => set({ priceValue }),
  comments: '',
  setComments: (newComment) => set({ comments: newComment }),
  paymentMethod: '',
  setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
  readTC: false,
  setReadTC: (readTC) => set({ readTC }),
  hasInvoiceData: false,
  setHasInvoiceData: (hasInvoiceData) => set({ hasInvoiceData }),
}));
