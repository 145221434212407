import { Card, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { Address } from '@/api/useAddresses';

type Props = {
  address: Address;
  selected: boolean;
  onSelect: (addressId: string) => void;
};

export const ShippingAddressCard = ({
  onSelect,
  selected,
  address: {
    addressId,
    firstName,
    lastName,
    company,
    address1,
    address2,
    city,
    postcode,
    country,
  },
}: Props) => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  return (
    <Card
      onClick={() => onSelect(addressId)}
      sx={() => ({
        backgroundColor: selected ? theme.colors.dark : 'white',
        color: selected ? 'white' : 'black',
        cursor: 'pointer',
        width: breakpoint ? '200px' : '150px',
        height: '200px',
        transition: 'background-color 0.5s, color 0.5s',
        '&:hover': {
          color: 'white',
          backgroundColor: theme.colors.dark,
        },
      })}
      key={addressId}
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
    >
      <Stack justify="space-between" style={{ height: '100%' }}>
        <Stack spacing="xs">
          <Text size="sm"> {`${firstName} ${lastName}`} </Text>
          {company && <Text size="xs"> {company}</Text>}
          <Text size="xs"> {`${address1} ${address2}`} </Text>
          <Text size="xs"> {`${postcode} ${city}`}</Text>
          <Text size="xs"> {country} </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
