import { Anchor, LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import useUserInvoiceDetails, {
  UserInvoice,
} from '@/api/useUserInvoiceDetails';
import { useCheckoutStore } from '@/store/checkout';

import { InvoiceInformation } from './components/InvoiceInformation';

export const Invoice = () => {
  const { userInvoiceInfo, isLoading } = useUserInvoiceDetails();
  const { setHasInvoiceData } = useCheckoutStore();

  useEffect(() => {
    if (isLoading) return;
    setHasInvoiceData(!!userInvoiceInfo?.nif);
  }, [isLoading, setHasInvoiceData, userInvoiceInfo?.nif]);

  if (isLoading) return <LoadingOverlay visible={isLoading}></LoadingOverlay>;

  if (!userInvoiceInfo?.nif) {
    return (
      <div>
        Antes de efetuar uma compra, deverá adicionar os dados de faturação e
        uma morada de envio{' '}
        <Anchor color="green" component={Link} to="/minha-conta">
          aqui.
        </Anchor>
      </div>
    );
  }

  return (
    <InvoiceInformation userInvoiceInfo={userInvoiceInfo as UserInvoice} />
  );
};
