import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/config/axiosClient';
import { useVoucher } from '@/hooks/useVoucher';
import { useCartStore } from '@/store/cart';

import { AddToCartResponse, ClearCartResponse } from './api';

const url = 'index.php?route=api/cart/addProduct';
const urlUpdate = 'index.php?route=api/cart/updateProduct';
const urlClear = 'index.php?route=api/cart/clear';

type AddCartParams = {
  productId: string;
  quantity: number;
};

const useCart = () => {
  const { setCart, voucher } = useCartStore();
  const { getVoucher } = useVoucher();
  const { mutate, isLoading } = useMutation(
    async ({
      productId,
      quantity,
    }: AddCartParams): Promise<AddToCartResponse> => {
      const { data } = await axiosInstance.post<AddToCartResponse>(url, {
        productId,
        quantity,
      });
      return data;
    },
    {
      onSuccess: (result: AddToCartResponse) => {
        const { cart } = result;
        if (cart !== null) setCart({ ...cart });
        if (voucher.code.length > 0) getVoucher({ coupon: voucher.code });
      },
    }
  );

  const { mutate: updateCart, isLoading: isUpdating } = useMutation(
    async ({
      productId,
      quantity,
    }: AddCartParams): Promise<AddToCartResponse> => {
      const { data } = await axiosInstance.post<AddToCartResponse>(urlUpdate, {
        productId,
        quantity,
      });
      return data;
    },
    {
      onSuccess: (result: AddToCartResponse) => {
        const { cart } = result;
        if (cart !== null) setCart({ ...cart });
        if (voucher.code.length > 0) getVoucher({ coupon: voucher.code });
        showNotification({
          title: 'Sucesso',
          message: 'Carrinho atualizado com sucesso.',
          color: 'green',
        });
      },
    }
  );

  const { mutate: clearCart, isLoading: isClearing } = useMutation(
    async (): Promise<ClearCartResponse> => {
      const { data } = await axiosInstance.post<ClearCartResponse>(urlClear);
      return data;
    },
    {
      onSuccess: (result: ClearCartResponse) => {
        const { cart } = result;
        if (cart !== null) setCart({ ...cart });
        showNotification({
          title: 'Sucesso',
          message: 'Carrinho atualizado com sucesso.',
          color: 'green',
        });
      },
    }
  );

  return {
    addToCart: mutate,
    isLoading,
    isUpdating,
    updateCart,
    isClearing,
    clearCart,
  };
};

export default useCart;
