import {
  Center,
  Container,
  Grid,
  Group,
  Loader,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetProducts } from '@/api/useGetProducts';
import { TopMenu } from '@/components/TopMenu';

import { CategoriesDropdown } from '../components/CategoriesDropdown/CategoriesDropdown';
import { CategoriesFilter } from '../components/CategoriesFilter';
import { ProductsList } from '../components/ProductsList';
import { useFilterStore } from '../filterStore/filterStore';

export const Store = () => {
  const { selectedCategory, page, perPage, searchName, setSearchName } =
    useFilterStore();

  const [urlParams] = useSearchParams();

  const theme = useMantineTheme();
  const { productsByCategory, isLoading } = useGetProducts({
    perPage,
    page,
    categoryId: selectedCategory,
    searchName,
  });

  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  useEffect(() => {
    const urlSearch = urlParams.get('search') as string;
    setSearchName(urlSearch);
  }, [setSearchName, urlParams]);

  return (
    <>
      <TopMenu />
      <Container size="xl">
        <Grid columns={12} py="100px" px={breakpoint ? '50px' : 0}>
          {breakpoint ? (
            <Grid.Col md={3}>
              <Stack>
                <CategoriesFilter />
              </Stack>
            </Grid.Col>
          ) : null}
          <Grid.Col md={9}>
            {!breakpoint ? (
              <Group mb="xl" position="right">
                <CategoriesDropdown />
              </Group>
            ) : null}
            {isLoading ? (
              <Center style={{ height: '100vh' }}>
                <Loader color="dark" />
              </Center>
            ) : (
              <>
                <ProductsList products={productsByCategory[selectedCategory]} />
              </>
            )}
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};
