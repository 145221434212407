import {
  Card,
  Image,
  Text,
  TextProps,
  MediaQuery,
  useMantineTheme,
  SegmentedControl,
  Group,
  Stack,
  Button,
  Center,
  Badge,
  Paper,
  Transition,
  Alert,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';

import useCart from '@/api/useCart';
import { useCartStore } from '@/store/cart';

type PriceProps = {
  price?: number;
  pricePerKg?: number;
  unit?: string;
} & Pick<TextProps, 'align' | 'size' | 'weight' | 'p'>;
const Price = ({
  price,
  pricePerKg,
  unit,
  weight,
  size,
  align,
  p = 0,
}: PriceProps) => {
  const priceToShow = unit?.toUpperCase() === 'KG' ? pricePerKg : price;

  return (
    <MediaQuery styles={{ fontSize: '14px' }} smallerThan="md">
      <Text weight={weight} size={size} p={p} align={align}>
        {Number(priceToShow).toFixed(2)}€/{unit?.toLowerCase()}
      </Text>
    </MediaQuery>
  );
};

type TitleProps = {
  name: string;
};
const Title = ({ name }: TitleProps) => {
  return (
    <Stack align="center" justify="center" style={{ minHeight: '50px' }}>
      <MediaQuery styles={{ fontSize: '16px' }} smallerThan="md">
        <Text p={0} weight={'bold'} align="center">
          {parse(DOMPurify.sanitize(name))}
        </Text>
      </MediaQuery>
    </Stack>
  );
};

type Props = {
  url: string;
  price: number;
  promotionPrice?: number;
  name: string;
  pricePerKg: number;
  promotionPricePerKg?: number;
  unit: string;
  promotionValue?: number;
  promotionDateEnd?: Date;
  weight?: number;
  productId: string;
  minimum: number;
  useFixedWith?: boolean;
};

export const ProductCard = ({
  url,
  price,
  name,
  promotionPrice,
  unit,
  pricePerKg,
  promotionPricePerKg,
  promotionValue,
  promotionDateEnd,
  weight = 0,
  productId,
  minimum,
  useFixedWith = false,
}: Props) => {
  const theme = useMantineTheme();
  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const [selectedUnit, setSelectedUnit] = useState(unit);
  const [valueToShow, setValueToShow] = useState(0);
  const [opened, setOpen] = useState(false);
  const { addToCart } = useCart();
  const {
    cart: { products },
  } = useCartStore();
  const navigate = useNavigate();
  const finalMinimum =
    ((products || []).find((p) => p.productId === Number(productId))
      ?.quantity || 1) >= minimum
      ? 1
      : minimum;

  const nrByKg = Math.max(
    finalMinimum,
    weight > 1000 || selectedUnit.toUpperCase() === 'UN'
      ? 1
      : Math.ceil(1000 / weight)
  );
  const cardStyle = useFixedWith
    ? {
        width: breakpoint ? '210px' : '47%',
        minHeight: breakpoint ? '350px' : '260px',
      }
    : {
        minWidth: breakpoint ? '210px' : '150px',
        minHeight: breakpoint ? '350px' : '260px',
      };

  return (
    <Card style={cardStyle} withBorder shadow="sm" p={0}>
      <Card.Section>
        <Title name={name} />
        <Image
          onClick={() => navigate(`/produto/${slugify(name)}`)}
          style={{
            cursor: 'pointer',
            minWidth: breakpoint ? '210px' : '150px',
          }}
          src={url}
          alt={name}
        />
      </Card.Section>
      <Stack spacing={0} style={{ minHeight: '100px' }}>
        {promotionPrice !== 0 && (
          <Group position="apart" style={{ position: 'relative' }}>
            <s>
              <Price
                weight={'bold'}
                size="sm"
                p="xs"
                price={price}
                pricePerKg={pricePerKg}
                unit={selectedUnit.toUpperCase()}
              />
            </s>
            <Paper
              shadow={'xl'}
              radius="sm"
              px={5}
              py={0}
              style={{
                position: 'absolute',
                right: 0,
                top: '-50%',
                color: 'white',
                backgroundColor: 'black',
              }}
            >
              <Stack spacing={0} align="center">
                <Group mt={-5} spacing={5}>
                  <Text p="0" size="xs">
                    + de
                  </Text>
                  <Text
                    color="green"
                    weight={'bold'}
                    p="0"
                    size={breakpoint ? 24 : 20}
                  >
                    {promotionValue}%
                  </Text>
                </Group>
                <Text mt={-10} size="xs">
                  de desconto
                </Text>
              </Stack>
            </Paper>
          </Group>
        )}
        <Center style={{ flexGrow: promotionPrice === 0 ? 1 : 0 }}>
          <Stack spacing={0}>
            <Price
              align="center"
              weight={'bold'}
              size="md"
              price={promotionPrice !== 0 ? promotionPrice : price}
              pricePerKg={
                promotionPrice !== 0 ? promotionPricePerKg : pricePerKg
              }
              unit={selectedUnit.toUpperCase()}
            />
            {promotionPrice !== 0 && promotionDateEnd && (
              <Badge variant="outline" color={'dark'}>
                até {promotionDateEnd?.getDate()}/
                {promotionDateEnd?.getMonth() + 1}
              </Badge>
            )}
          </Stack>
        </Center>
      </Stack>
      <Stack style={{ position: 'relative' }}>
        <Transition
          mounted={opened}
          transition="slide-up"
          duration={400}
          timingFunction="ease"
        >
          {(styles) => (
            <Alert
              radius="md"
              variant="light"
              color="green"
              style={{
                ...styles,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                transform: 'translateY(-100%)',
              }}
            >
              Adicionou {valueToShow} {valueToShow > 1 ? 'produtos' : 'produto'}{' '}
              ao carrinho
            </Alert>
          )}
        </Transition>
        <Group grow spacing={0}>
          {unit?.toLowerCase() !== 'un' && (
            <SegmentedControl
              onChange={setSelectedUnit}
              value={selectedUnit?.toUpperCase()}
              color={'dark'}
              size="xs"
              data={['UN', 'KG']}
            />
          )}
          <Button
            color="dark"
            size="xs"
            p={0}
            onClick={() => {
              setValueToShow(nrByKg);
              setTimeout(() => setOpen(false), 1000);
              setOpen(true);
              addToCart({ productId, quantity: nrByKg });
            }}
          >
            Adicionar
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};
