import { Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';

import useCart from '@/api/useCart';
import { ConfirmationModal } from '@/components/ConfirmationModal';

import { Desktop } from './components/Desktop';
import { Mobile } from './components/Mobile';

type Props = {
  products: CartProduct[];
};

export const CartTable = ({ products }: Props) => {
  const theme = useMantineTheme();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { clearCart } = useCart();

  const breakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const Component = breakpoint ? Desktop : Mobile;

  const handleOnConfirm = () => {
    clearCart();
    setIsModalOpened(false);
  };

  return (
    <>
      <ConfirmationModal
        title="Limpar o carrinho de compras"
        onConfirm={handleOnConfirm}
        onClose={() => setIsModalOpened(false)}
        isOpened={isModalOpened}
      >
        <Text size="sm">
          Tem a certeza que pretende limpar o carrinho de compras? Esta acção é
          irreversível.
        </Text>
      </ConfirmationModal>
      <Component
        onClearCart={() => setIsModalOpened(true)}
        products={products}
      />
    </>
  );
};
