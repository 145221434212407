import { Route, Routes } from 'react-router-dom';

import { AboutUs } from './AboutUs';

export const AboutUsRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<AboutUs />} path="/" />
    </Routes>
  );
};
