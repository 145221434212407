import {
  Accordion,
  Anchor,
  Container,
  Group,
  List,
  Stack,
  Text,
  Title,
} from '@mantine/core';

import { TopMenu } from '@/components/TopMenu';

export const FAQ = () => {
  return (
    <>
      <TopMenu />
      <Container size="md" py="60px">
        <Stack>
          <Group position="center">
            <Title>Perguntas frequentes</Title>
          </Group>
          <Accordion>
            <Accordion.Item value="1">
              <Accordion.Control>
                <Text weight={'bold'}>Onde posso receber o meu pedido?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                Em sua casa ou local de trabalho, conforme seja mais conveniente
                para si.
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="2">
              <Accordion.Control>
                <Text weight={'bold'}>
                  {' '}
                  Quais as localidades onde a FRUTA DA 5ª já realiza as suas
                  entregas?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Neste momento a FRUTA DA 5ª realiza entregas em Coimbra,
                Montemor-o-Velho, Soure, Condeixa, Figueira da Foz e Cantanhede.
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="3">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Com que antecedência devo fazer o meu pedido?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Recomendamos que faça o seu pedido até às 15h do dia anterior.
                Desta forma fica mais fácil garantir todos artigos que deseja.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="4">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Qual o horário para fazerem a entrega em minha casa?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Com a FRUTA DA 5ª pode escolher um período que seja mais
                conveniente entre as 9h e as 21h para receber a sua encomenda.
                <List ml="lg" mt="xs" spacing="xs" center>
                  <List.Item>
                    Todo dia (9h - 21h) <b>ECO</b>
                  </List.Item>
                  <List.Item>Manhã (9h - 12h)</List.Item>
                  <List.Item> Almoço (12h - 14h)</List.Item>
                  <List.Item>Tarde (14h - 18h)</List.Item>
                  <List.Item>Noite (18h - 21h)</List.Item>
                </List>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="5">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Devo pagar a encomenda assim que recebo a informação com o
                  pedido?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sugerimos que faça o pagamento depois de receber a factura.
                <br />
                Na preparação do seu cabaz podem existir diferenças de pesos e a
                factura é emitida e enviada por email logo após a preparação.
                <br />
                Desta forma só paga o que efetivamente recebe.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="6">
              <Accordion.Control>
                <Text weight={'bold'}>Como posso realizar o pagamento?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                Com a FRUTA DA 5ª pode escolher um período que seja mais
                conveniente entre as 9h e as 21h para receber a sua encomenda.
                <List ml="lg" mt="xs" spacing="xs" center>
                  <List.Item>MBWay</List.Item>
                  <List.Item>NETBANCO ou Transferência Bancária</List.Item>
                  <List.Item>
                    MB / TPA (Recomendamos a utilização com QR-CODE ou MB WAY)
                  </List.Item>
                  <List.Item>Cartão Refeição</List.Item>
                  <List.Item>Cartão Refeição Euroticket</List.Item>
                </List>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="7">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Se escolher a opção MBWAY ou NETBANCO/Transferência Bancária
                  como devo realizar pagamento?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Para a opção NETBANCO/Transferência Bancária o pagamento deve
                ser feito logo após receber a factura por e-mail. A factura é
                emitida e enviada por email logo após a preparação do seu cabaz.
                Só desta forma paga o que efetivamente recebe.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="8">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Como é que eu sei se os produtos são realmente de qualidade?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Na FRUTA DA 5ª a sua satisfação é um dos nossos maiores
                objetivos.
                <br />
                No acto da entrega pode sempre verificar todos os seus produtos
                e caso algo não esteja do seu agrado faremos de imediato a
                recolha.
                <br />
                Se algo falhar, fale connosco. Nós agradecemos.
                <br />O seu feedback é muito importante para podermos melhorar e
                assim servi-lo melhor. (Veja ou deixe o seu{' '}
                <Anchor
                  color="green"
                  href="https://www.facebook.com/Frutada5a/reviews/"
                >
                  {' '}
                  feeback
                </Anchor>
                )
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="9">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Os Produtos são sempre de origem Nacional?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Privilegiamos frutas da época e os produtos Nacionais, no
                entanto caso a disponibilidade ou a relação preço/qualidade seja
                mais vantajosa para si, podemos optar por produtos estrangeiros.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="10">
              <Accordion.Control>
                <Text weight={'bold'}>
                  É possível adquirir frutas e legumes mais baratos em mercados,
                  hipermercados e mercearias?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                É possível encontrar produtos a preços inferiores. No entanto,
                confira a qualidade, origens e até o calibre.
                <br />
                Certamente não irá encontrar a mesma qualidade da fruta e dos
                legumes.
                <br />
                Com a FRUTA DA 5ª as entregas também são grátis e não paga
                portes.
                <br />
                Privilegiamos ainda uma estreita e personalizada relação com os
                nossos clientes. Para nós cada cliente é único!
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="11">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Os Produtos da FRUTA DA 5ª são biológicos?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Muitos dos produtos são recolhidos directamente do produtor.
                Existem requisitos como a produção integrada que privilegiamos.
                <br />
                Quando tivermos garantia de um dos nossos produtores que os
                produtos sejam biológicos e certificados, esse certificado será
                transmitido sempre aos nossos clientes.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="12">
              <Accordion.Control>
                <Text weight={'bold'}>As entregas são sempre grátis?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                Regularmente sim. Optámos por realizar as entregas grátis e
                definir um valor mínimo para as encomendas. Consideramos que
                assim o cliente pode usufruir da totalidade do valor pago.
                <br />
                Se o valor da primeira encomenda for inferior a 40€, poderá ser
                aplicada uma Taxa de entrega de valor igual a 5€.
                <br />
                Todos os clientes que não tenham feito qualquer compra num
                intervalo de 3 meses irá ser considerada taxa de entrega para
                encomendas de valor inferior a 40€
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="13">
              <Accordion.Control>
                <Text weight={'bold'}>Existe valor mínimo por encomenda?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. Existe um valor mínimo para a sua encomenda. Só desta forma
                conseguimos realizar a entrega grátis em sua casa ou local de
                trabalho.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="14">
              <Accordion.Control>
                <Text weight={'bold'}>
                  O que acontece se a encomenda não chegar ao valor mínimo?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Caso o seu pedido não atinja o valor mínimo, a FRUTA DA 5ª
                completa-o com um sortido de frutas.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="15">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Posso escolher os artigos por peso ou posso só indicar as
                  quantidades?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                ara a maioria dos artigos pode selecionar da forma que seja mais
                fácil para si, indicando o peso ou as quantidades pretendidas.
                <br />
                Existem artigos que só podem ser vendidos à unidade.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="16">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Como são facturados os artigos da minha encomenda?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                A maioria dos artigos produtos são pesados e facturados através
                da relação preço/quilograma. Existem outros como as embalagens
                de frutos secos que são facturadas à unidade.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="17">
              <Accordion.Control>
                <Text weight={'bold'}>
                  O valor final do pedido vai coincidir sempre com o valor final
                  a pagar?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Não. Valor final do seu pedido é meramente indicativo.
                Comercializamos os nossos produtos de uma forma simples,
                realista e honesta.
                <br />
                Todos os artigos são pesados e facturados com o seu valor real.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="18">
              <Accordion.Control>
                <Text weight={'bold'}>
                  O Valor apresentado para os produtos já inclui o valor dos
                  impostos?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. Todos os produtos apresentados na Loja online já incluem o
                valor de imposto legalmente estabelecido (IVA).
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="19">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Os preços na loja online podem oscilar diariamente?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. Os valores apresentados podem ser actualizados diariamente,
                no entanto garantimos sempre o preço apresentado quando finaliza
                a sua compra na loja online.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="20">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Os valores apresentados na loja online coincidem sempre com os
                  valores apresentados em Feiras ou Eventos?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Não. A fruta é um bem perecível. Por vezes existe a necessidade
                de ajustar os preços consoante as quantidades ou até o tipo de
                eventos.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="21">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Os valores dos artigos disponíveis para eu receber em casa têm
                  o mesmo valor dos artigos disponíveis para revenda ou para
                  outro serviço?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Para cada serviço podem existir condições específicas, mediante
                acordo entre as partes.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="22">
              <Accordion.Control>
                <Text weight={'bold'}>
                  Existem outros produtos além dos apresentados na loja online?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. É possível adquirir outros produtos da época, quer sejam
                nacionais ou estrangeiros.
                <br />
                Caso não encontre o que deseja entre em contacto com a FRUTA DA
                5ª e fale connosco.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="23">
              <Accordion.Control>
                <Text weight={'bold'}>As caixas são oferta?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                Não. As caixas apenas servem para transportar os artigos que
                solicitou até sua casa
                <br />
                São propriedade da Fruta da 5ª e devem ser devolvidas na próxima
                encomenda.
                <br />
                Acreditamos que desta forma, poupamos o ambiente sem desperdiçar
                embalagens.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="24">
              <Accordion.Control>
                <Text weight={'bold'}>Onde se localiza a vossa loja?</Text>
              </Accordion.Control>
              <Accordion.Panel>
                A Fruta 5ª não tem uma loja física. Apenas loja online
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="25">
              <Accordion.Control>
                <Text weight={'bold'}>
                  As fotos dos artigos correspondem aos artigos que vou receber
                  em casa?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. Isso acontece na maioria das vezes.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="26">
              <Accordion.Control>
                <Text weight={'bold'}>
                  A Fruta incluída nos Cabazes empresa é de fácil consumo num
                  escritório?
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                Sim. A Fruta incluída no serviço Empresa é de fácil consumo no
                seu local de trabalho. No entanto caso pretenda incluir fruta
                como Melão, Melancia, Abacaxi ou até Manga na sua empresa,
                estamos recetivos a esse pedido.
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </Container>
    </>
  );
};
