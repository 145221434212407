import { Route, Routes } from 'react-router-dom';

import { Product } from './Product';

export const ProductRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Product />} path="/:slug" />
    </Routes>
  );
};
