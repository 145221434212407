import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/config/axiosClient';

import { UpdateInvoiceDetailsResponse } from './api';

const editUrl = 'index.php?route=api/customer/editCustomerInvoiceDetails';
const createUrl = 'index.php?route=api/customer/newCustomerInvoiceDetails';

type UpdateInvoiceDetailsParams = {
  addressId?: number;
  firstName: string;
  lastName: string;
  company: string;
  street: string;
  other: string;
  city: string;
  zipCode1: string;
  zipCode2: string;
  countryId: string;
  nif: string;
};

type UpdateInvoiceDetailsResult = {
  success: string;
};

type ErrorUpdateInvoiceDetails = {
  firstname?: string;
  lastname?: string;
  address_1?: string;
  city?: string;
};

const useUpdateInvoiceDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data } = useMutation<
    UpdateInvoiceDetailsResult,
    AxiosError<ErrorUpdateInvoiceDetails>,
    UpdateInvoiceDetailsParams,
    unknown
  >(
    async ({
      addressId,
      firstName,
      lastName,
      company,
      street,
      other,
      city,
      zipCode1,
      zipCode2,
      countryId,
      nif,
    }: UpdateInvoiceDetailsParams): Promise<UpdateInvoiceDetailsResult> => {
      const url = addressId ? editUrl : createUrl;
      const { data } = await axiosInstance.post<UpdateInvoiceDetailsResponse>(
        url,
        {
          address1: street,
          address2: other,
          city,
          company,
          countryId,
          postcode: `${zipCode1}-${zipCode2}`,
          firstname: firstName,
          lastname: lastName,
          addressId,
          custom_field: { 1: nif },
        }
      );
      return {
        success: data.success,
      };
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['useUserInvoiceDetails']);
      },
    }
  );

  return { updateAccessDetails: mutate, isSaving: isLoading, data };
};

export default useUpdateInvoiceDetails;
