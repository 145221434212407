import { Button, Group, Image, Stack, Table, Tooltip } from '@mantine/core';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

import useCart from '@/api/useCart';
import { NumberInputWithButtons } from '@/components/NumberInputWithButtons';

type Props = {
  products: CartProduct[];
  onClearCart: () => void;
};

export const Desktop = ({ products = [], onClearCart }: Props) => {
  const { updateCart, isClearing, isUpdating } = useCart();

  const updateValue = (
    minimum: number,
    newQuantity: number,
    productId: number
  ) => {
    if (newQuantity < minimum) return;
    else updateCart({ productId: `${productId}`, quantity: newQuantity });
  };

  const handleClearCart = () => onClearCart && onClearCart();

  return (
    <Stack>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Nome</th>
            <th>Unidades</th>
            <th>Peso aproximado</th>
            <th>Preço</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {products
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((product) => (
              <tr key={product.productId}>
                <td>
                  <Image
                    radius={'md'}
                    style={{ width: '80px' }}
                    src={product.thumb}
                    alt={product.name}
                  />
                </td>
                <td>
                  {parse(DOMPurify.sanitize(product.name))}
                  {product.minimum > 1 ? (
                    <Tooltip label={`Quantidade mínima: ${product.minimum}`}>
                      <i className="icon-info-circled" />
                    </Tooltip>
                  ) : null}
                </td>
                <td>
                  <NumberInputWithButtons
                    buttonSize={36}
                    inputWidth={60}
                    minimum={product.minimum}
                    value={product.quantity}
                    onChange={debounce(
                      (newQuantity) =>
                        updateValue(
                          product.minimum,
                          newQuantity,
                          product.productId
                        ),
                      300
                    )}
                  />
                </td>

                <td>{Number(product.totalWeight / 1000).toFixed(2)} kg</td>
                <td>{Number(product.price).toFixed(2)}€</td>
                <td>{Number(product.totalValue).toFixed(2)}€</td>
                <td>
                  <Button
                    disabled={isUpdating}
                    color="dark"
                    variant="subtle"
                    size="xs"
                    onClick={() =>
                      updateCart({
                        productId: `${product.productId}`,
                        quantity: 0,
                      })
                    }
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Group position="right">
        <Button
          component={Link}
          disabled={isClearing}
          loading={isClearing}
          to="/loja"
          size="xs"
          color="green"
          variant="outline"
        >
          Continuar a comprar
        </Button>
        <Button
          disabled={isClearing}
          loading={isClearing}
          onClick={handleClearCart}
          size="xs"
          color="dark"
          variant="outline"
        >
          Limpar carrinho
        </Button>
      </Group>
    </Stack>
  );
};
