import { addDays, eachDayOfInterval } from 'date-fns';

export const REQUIRED_FIELD = 'Campo obrigatório';

const getWorkingDays = () => {
  const workingDays = [1, 2, 3, 4, 5, 6];
  const now = new Date();
  const result = eachDayOfInterval({
    start: now,
    end: addDays(now, 6),
  });
  return result.reduce<number[]>((agg, date) => {
    if (workingDays.includes(date.getDay())) return [...agg, date.getDay()];
    return [...agg];
  }, []);
};

export const workingDays = getWorkingDays();
export const DAYS_OF_WEEK: { [x: number]: string } = {
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
};

export const CUSTOM_SCHEDULE_ID = -1;

export const TRACKING_ID = 'G-H7V207J3DB'; // OUR_TRACKING_ID
