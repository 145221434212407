import {
  Anchor,
  Container,
  Group,
  Image,
  Stack,
  Text,
  Title,
} from '@mantine/core';

import { TopMenu } from '@/components/TopMenu';

export const TermsAndConditions = () => {
  return (
    <>
      <TopMenu />
      <Container size="md" py="60px">
        <Stack>
          <Group>
            <Title>Termos e Condições</Title>
          </Group>
          <Text size="sm">
            O acesso e utilização deste site, bem como os produtos e serviços
            disponíveis estão sujeitos às condições que apresentamos de seguida.
            <br />
            <br />
            Ao utilizar este site, o cliente está a concordar com as condições
            de utilização do mesmo, as quais podem ser atualizadas
            ocasionalmente. Aconselhamos todos os nossos clientes a visitar esta
            página regularmente para se manterem a par de quaisquer alterações
            ou atualizações.
            <br />
            <br />O acesso a este site é permitido com caráter temporário e
            reservamo-nos o direito de retirar ou alterar serviços ou produtos
            sem aviso prévio. Não nos responsabilizamos se, por qualquer razão,
            este site estiver indisponível durante determinado período de tempo.
            Podemos, ocasionalmente, restringir o acesso a algumas áreas do
            mesmo ou ao acesso por alguns utilizadores.
            <br />
            <br />A FRUTA DA 5ª não se responsabiliza por danos ou perdas que
            possam advir da utilização de qualquer informação contida neste
            site.
            <br />
            <br />
            Algumas hiperligações deste site redirecionam o cliente para sites
            externos ou parcerias da FRUTA DA 5ª. Ao clicar nessas
            hiperligações, estará a deixar o site da FRUTA DA 5ª e, como não
            temos qualquer controlo sobre esses sites, não nos responsabilizamos
            pela informação presente nos mesmos.
            <br />
            <br />
            Para efetuar uma encomenda, o cliente tem de ser maior de 18 anos e
            compromete-se a fornecer dados verdadeiros que permitam o
            processamento e entrega com sucesso da sua encomenda.{' '}
          </Text>
          <Stack spacing="xs">
            <Text size="lg" weight={'bold'}>
              Privacidade
            </Text>
            <Text size="sm">
              Na FRUTA DA 5ª, temos uma importante responsabilidade e
              compromisso no que respeita à segurança do nosso site e à proteção
              dos dados dos nossos clientes e visitantes.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Que informação recolhemos? </Text>
            <Text size="sm">
              Na Fruta da 5ª, recolhemos o nome, número de telefone, endereço
              postal, endereço de e-mail e número de contribuinte de cada
              cliente para processar as encomendas e proceder à entrega. Podemos
              também recolher outras informações sobre a experiência do cliente
              no nosso site que contribuem para a melhoria do nosso serviço.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>
              Para que efeito são utilizados os dados recolhidos?
            </Text>
            <Text size="sm">
              Todos os dados do cliente que solicitamos permitem processar as
              suas encomendas, notificá-lo acerca de alterações importantes nas
              funcionalidades do site, realizar inquéritos e obter informações
              para efeitos estatísticos. Podemos também enviar-lhe
              periodicamente por e-mail ou por SMS informações sobre produtos e
              serviços, campanhas, promoções e ofertas especiais. Se o cliente
              não desejar ser contactado para estes efeitos, poderá cancelar
              esses envios enviando email para geral@frutada5a.pt a solicitar o
              cancelamento do envio de e-mails e/ou SMS. A FRUTA DA 5ª
              compromete-se a proteger os dados do cliente e estes nunca serão
              disponibilizados a terceiros. Aquando do processamento da
              encomenda do cliente, é possível que os seus dados, como o
              endereço e código postal, sejam divulgados para efeitos de
              prevenção e deteção de fraudes.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Atualização dos dados do cliente</Text>
            <Text size="sm">
              Se o cliente desejar verificar ou actualizar os dados que submeteu
              à FRUTA DA 5ª, poderá fazê-lo na área “Minha Conta”. O cliente
              deverá manter os dados de acesso à sua conta seguros, dado que
              toda e qualquer ação ou pedido efetuados através da sua conta
              serão da sua responsabilidade. Recomendamos que o cliente não
              guarde a sua palavra-passe no browser, uma vez que outra pessoa
              com acesso ao seu computador pode ver e utilizar a sua informação
              pessoal.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Agendamento da entrega ao Domicílio </Text>A
            <Text size="sm">
              FRUTA DA 5ª disponibiliza-lhe algumas possibilidades de escolha do
              dia e hora em que mais lhe convém receber o seu pedido. Fazemos o
              nosso melhor para que a sua encomenda seja entregue no dia e no
              período por si selecionado. No caso de ocorrer um imprevisto
              entraremos em contacto consigo para se necessário reagendar a
              entrega.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Taxa de entrega</Text>
            <Text size="sm">
              A TAXA DE ENTREGA apenas poderá ser aplicada na{' '}
              <u>primeira compra</u>, caso o valor da mesma seja inferior a 40.{' '}
              <b>Valor taxa de entrega: 5€</b>
              <br />
              <b>
                NOTA: Todos os clientes que não tenham feito qualquer compra no
                espaço de 3 meses deve ser considerada novamente taxa de
                entrega.
              </b>
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Existe valor mínimo por encomenda?</Text>
            <Text size="sm">
              <b>Sim.</b> Existe um valor mínimo para a sua encomenda. Só desta
              forma conseguimos realizar a entrega grátis em sua casa ou local
              de trabalho.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Pagamento</Text>
            <Text size="sm">
              Pode optar por realizar o pagamento através de:
              <ul>
                <li>MBWay</li>
                <li>NETBANCO ou Transferência Bancária</li>
                <li>
                  MB / TPA (Recomendamos a utilização com QR-CODE ou MB WAY)
                </li>
                <li>Cartão Refeição - Cartão Refeição Euroticket</li>
              </ul>
              Para a opção <b>NETBANCO/Transferência Bancária</b> o pagamento
              deve ser feito logo após receber a factura por e-mail. A factura é
              emitida e enviada por email logo após a preparação do seu cabaz.
              Só desta forma paga o que efetivamente recebe.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Caixas vazias</Text>
            <Text size="sm">
              <b>
                O cliente compromete-se a guardar e devolver as caixas na
                próxima encomenda.
              </b>
              Só desta forma conseguimos não debitar as caixas vazias.
              Acreditamos que, assim,{' '}
              <b>
                poupamos o ambiente sem desperdiçar mais embalagens ou sacos.
              </b>
              <br />
              <b>Todas as caixas não devolvidas têm um custo de 2€.</b>
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Propriedade Intelectual e Conteúdos</Text>
            <Text size="sm">
              {' '}
              O cliente pode guardar, imprimir ou apresentar os conteúdos deste
              site apenas para o seu uso pessoal.
              <br /> Não é permitida a publicação, manipulação, distribuição ou
              reprodução, em qualquer formato, de qualquer conteúdo deste site,
              nem a ligação do mesmo a qualquer negócio ou empresa.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>
              Cores, imagens, calibres e descrição dos produtos
            </Text>
            <Text size="sm">
              Fazemos todos os esforços para mostrar as cores e as
              características dos nossos produtos (todas as imagens são nossa
              propriedade e servem apenas para fins de ilustração). O tamanho
              que vê vai depender da resolução do seu monitor. Nós não podemos
              garantir que a exibição do monitor de qualquer cor e tamanho irá
              sempre reflectir com precisão a imagem do produto que pretende.
              Nós podemos, de vez em quando, variar nos calibres, pesos e até
              nas cores dos produtos apresentados na nossa página sem aviso
              prévio. No entanto no acto da entrega pode sempre verificar todos
              os seus produtos e caso algo não esteja do seu agrado faremos de
              imediato a recolha.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Como encomendar</Text>
            <Text size="sm">
              A loja da Fruta da 5ª permite fazer encomendas de forma fácil,
              rápida e intuitiva. <br />
              Para receber os seus produtos num endereço à sua escolha, terá
              apenas de seguir alguns passos simples.
              <br />
              Começa por escolher os produtos que deseja comprar, indicando o
              número de unidades ou indicando o peso pretendido.
              <br />
              Qualquer que seja a forma que escolheu para fazer a encomenda, os
              valores a pagar são sempre indicativos, pois todos os produtos são
              sempre faturados através da relação preço/quilograma. Exemplo:
              <br />
              <br />
              <Image
                maw={600}
                src={'https://frutada5a.pt/image/catalog/tabela-mobile.PNG'}
                alt="cabaz"
                withPlaceholder
                placeholder={<Text align="center">tabela exemplo</Text>}
              />
              <br />
              <br />
              Desta forma o valor final do seu pedido também é meramente
              indicativo. O Valor real será sempre correspondente ao peso que
              recebe.
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>
              Cancelamento encomenda ou devolução artigos
            </Text>
            <Text size="sm">
              {' '}
              O processo de cancelamento ou devolução é tratado individualmente
              pela Frutada5a Unip Lda conforme legislação portuguesa. <br />O
              pedido deverá chegar por escrito para o email{' '}
              <Anchor color="green" href="mailto='reclamacoes@frutada5a.pt'">
                reclamacoes@frutada5a.pt
              </Anchor>
              . No prazo máximo de 14 dias após receber a encomenda. As
              instruções para cancelamento da encomenda ou devolução do artigo
              da encomenda são definidas pela Frutada5a Unip Lda também por
              email. <br />
              Após efetuar o pedido de devolução, o cliente deverá enviar o
              produto que pretende devolver, por transportadora, suportando os
              respetivos custos ou por uma forma acordada com a Frutada5a Unip
              Lda sem qualquer custo adicional.
              <br /> O cliente pode ser responsabilizado pela depreciação do
              bem, se a manipulação efetuada para inspecionar a natureza, as
              características e o funcionamento desse bem exceder a manipulação
              que habitualmente é admitida em estabelecimento comercial.
              <br />
              <br />
              <b>
                São consideradas todas as exceções conforme legislação
                portuguesa ao direito de livre resolução.
              </b>
              <br />
              <br /> Não podem ser devolvidos bens personalizados de acordo com
              especificações do cliente.
              <br /> Não pode ser devolvido bens que, por natureza, não possam
              ser reenviados ou sejam suscetíveis de se deteriorarem ou ficarem
              fora de prazo. <br />
              Todos os bens selados não suscetíveis de devolução, por motivos de
              proteção da saúde ou de higiene quando abertos após a entrega.{' '}
            </Text>
          </Stack>
          <Stack spacing="xs">
            <Text weight={'bold'}>Gestão de conflitos</Text>
            <Text size="sm">
              Em caso de litígio o consumidor pode recorrer a uma entidade de
              resolução alternativa de consumo:{' '}
              <Anchor color="green" href="https://cacrc.pt/">
                Centro de Arbitragem de Conflitos de Consumo do Distrito de
                Coimbra
              </Anchor>
            </Text>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
